<div class="wrapper" fxLayout="column" fxLayoutAlign="center">
  <div class="header" fxLayout fxLayoutAlign="space-between center">
    <span>{{ 'EDIT_COMMENT' | translate }}</span>
    <mat-icon (click)="onClose()">close</mat-icon>
  </div>
  <mat-form-field appearance="outline" [formGroup]="commentGroup">
    <mat-label>Comment</mat-label>
    <input formControlName="comment" matInput>
  </mat-form-field>
  <button mat-button (click)="onSave()">Save</button>
</div>
