import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DateCustomfieldComponent } from './date-customfield/date-customfield.component';
import { BooleanCustomfieldComponent } from './boolean-customfield/boolean-customfield.component';
import { HyperlinkCustomfieldComponent } from './hyperlink-customfield/hyperlink-customfield.component';
import { PlannedCallsForTodayComponent } from './planned-calls-for-today/planned-calls-for-today.component';
import { RatingWithCommentInputComponent } from './rating-with-comment-input/rating-with-comment-input.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { SingleSelectCustomfieldComponent } from './single-select-customfield/single-select-customfield.component';
import { TextCustomfieldComponent } from './text-customfield/text-customfield.component';

@NgModule({
  declarations: [
    RatingWithCommentInputComponent,
    DateCustomfieldComponent,
    BooleanCustomfieldComponent,
    HyperlinkCustomfieldComponent,
    PlannedCallsForTodayComponent,
    SingleSelectCustomfieldComponent,
    TextCustomfieldComponent
  ],
  exports: [
    RatingWithCommentInputComponent,
    DateCustomfieldComponent,
    BooleanCustomfieldComponent,
    HyperlinkCustomfieldComponent,
    PlannedCallsForTodayComponent,
    SingleSelectCustomfieldComponent,
    TextCustomfieldComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TranslateModule
  ]
})
export class CustomfieldsModule {
}
