import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TaskServiceService } from '../../../../planner/task-service.service';
import { RelayIdService } from '../../../../shared/relay-id.service';
import { UserprofileService } from '../../../../shared/userprofile.service';
import { environment } from '../../../../../environments/environment';
import {
  GetOrderForPrefillGQL, GetOrderForTaskCreateSearchGQL, MeFieldsFragment, OrderType
} from '../../../../../generated/graphql';
import { AllUserProfilesGQL, UserProfile } from '../../../../graphql/graphql';

@Component({
  selector: 'app-problem-dialog',
  templateUrl: './problem-dialog.component.html',
  styleUrls: ['./problem-dialog.component.scss']
})
export class ProblemDialogComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();
  newMetricValueForm: FormGroup;
  searchProjectManagerCtrl = new FormControl('');
  foundOrders: OrderType[];
  projectManagers: UserProfile[] = [];
  managers: UserProfile[] = [];
  selectedCompany;
  selectedCompanyWithOldId;
  importantStatus = false;
  id;
  comment;
  metricGroupForOtherProblemId;
  public user: MeFieldsFragment;

  get orderCtrl() {
    return this.newMetricValueForm.controls['order'];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {
      metricGroupForOtherProblemId: string;
    },
    private formBuilder: FormBuilder,
    private taskService: TaskServiceService,
    private dialogRef: MatDialogRef<ProblemDialogComponent>,
    private relayService: RelayIdService,
    private userprofileService: UserprofileService,
    private getOrderForPrefillGQL: GetOrderForPrefillGQL,
    private ordersGQL: GetOrderForTaskCreateSearchGQL,
    private allUserProfilesGQL: AllUserProfilesGQL
  ) {
    this.metricGroupForOtherProblemId = data.metricGroupForOtherProblemId;
  }

  ngOnInit(): void {
    if (environment.env === 'prod') {
      this.id = 21;
    } else {
      this.id = 11;
    }

    this.newMetricValueForm = this.formBuilder.group({
      date: new Date(),
      comment: new FormControl(''),
      company: new FormControl(''),
      order: new FormControl(null),
      manager: new FormControl(null, [Validators.required]),
      isImportant: false
    });

    this.loadUserData();
    this.initOrderSelect();
    this.initManagerSelect();
  }

  loadUserData() {
    this.userprofileService.getMe().subscribe(profile => this.user = profile.data?.me);
  }

  private initOrderSelect() {
    this.subscription.add(
      this.orderCtrl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
        if (typeof value === 'string') {
          this.destroy$.next();
          this.ordersGQL.watch({ search: value, first: 500 }, { fetchPolicy: 'no-cache' }).valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              resp => this.foundOrders = resp.data.ordersPaginated.edges.map(item => item.node as OrderType),
              () => this.foundOrders = null
            );
        } else if (value?.id) {
          this.onCompanyChange(value.company);
          this.selectedCompanyWithOldId = {
            ...value.company,
            id: value.company?.id
              ? this.relayService.convertRelayTypes(value.company.id, 'CompanyModelType')
              : undefined
          };
        }
      })
    );
  }

  initManagerSelect() {
    this.allUserProfilesGQL.fetch().subscribe(resp => {
      this.managers = resp.data.userprofiles;
      this.projectManagers = [...resp.data.userprofiles];

      this.subscription.add(this.searchProjectManagerCtrl.valueChanges.subscribe(() => this.projectManagerSearch()));
    });
  }

  projectManagerSearch() {
    const filter = this.searchProjectManagerCtrl.value?.toLowerCase() || '';
    this.projectManagers = this.managers.filter(option => {
      return option?.user?.firstName?.toLowerCase().includes(filter) ||
        option?.user?.lastName?.toLowerCase().includes(filter);
    });
  }

  onSubmit() {
    const metricValue = {
      ...this.newMetricValueForm.getRawValue(),
      client: this.newMetricValueForm.getRawValue().company,
      value: 1,
      createdBy: this.user.id,
      date: new Date(this.newMetricValueForm.getRawValue().date).toLocaleDateString('lt-LT'),
      order: this.newMetricValueForm.getRawValue().order?.id || null,
      metricGroup: this.metricGroupForOtherProblemId || btoa(`MetricGroupType:${this.id}`)
    };
    this.comment = metricValue.comment;
    delete metricValue.comment;

    this.taskService.createMetricValue(metricValue, ['GetOperationsTasks']).subscribe(
      response => {
        const id = response.data.createMetricValue.metricValue.id;

        if (this.comment) {
          this.taskService.createMetricValueComment({
            metricValue: id,
            comment: this.comment + ` | Created by: ${this.user.fullName}`
          }).subscribe(
          () => this.dialogRef.close(true)
          );
        } else {
          this.dialogRef.close(true);
        }
      }
    );
  }

  onCompanyChange($event: any) {
    const newCompanyId = $event?.id ? this.relayService.convertRelayTypes($event?.id || '', 'CompanyType') : null;
    this.newMetricValueForm.get('company').setValue(newCompanyId);
    this.selectedCompany = {
      id: newCompanyId,
      name: $event?.name
    };
  }

  orderDisplayFn(order: OrderType): string | undefined {
    return order
      ? `${ atob(order.id).split(':')[1] }: ${order.specification?.positionTitle || '' } (${ order.company?.name || '' })`
      : undefined;
  }

  decode(item: string): string {
    return atob(item);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
}
