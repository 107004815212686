<div class="filter-row" fxLayout="row" fxLayoutAlign="space-between center">
  <app-filter-bar
    [filters]="filters"
    (filterChanges)="onFilterChanges($event)"
  ></app-filter-bar>
</div>

<div class="wrapper">
  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  </div>
  <table mat-table class="full-width-table" matSort>
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TIME' | translate }}</th>
      <td mat-cell *matCellDef="let activity">
        <span>{{ moment(activity.createdAt).format('YYYY-MM-DD HH:mm') }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="created_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLIENT.PROJECT_MANAGER' | translate }}</th>
      <td mat-cell *matCellDef="let activity">
        <span>{{ activity.createdBy?.fullName }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CONTACT' | translate }}</th>
      <td mat-cell *matCellDef="let activity">
        <span>{{ getContactName(activity) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PHONE' | translate }}</th>
      <td mat-cell *matCellDef="let activity">
        <span>{{ activity.phone }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>{{ 'COMPANY' | translate }}</th>
      <td mat-cell *matCellDef="let activity">
        <span>{{ activity.clientContact?.company?.name }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef>{{ 'SINGLE_REASON' | translate }}</th>
      <td mat-cell *matCellDef="let activity">
        <mat-form-field>
          <mat-select [value]="activity.reason.title || '...'">
            <mat-option *ngFor="let reason of reasons" [value]="reason.title" (click)="onUpdate(activity.id, reason.id)">
              {{ reason.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>{{ 'COMMENT' | translate }}</th>
      <td class="editable-comment" mat-cell *matCellDef="let activity">
        <span>{{ activity.comment }}</span>
        <button mat-icon-button (click)="onOpenEditDialog(activity.id, activity.comment)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
    [length]="dataSource?.totalCount"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[10, 25, 50, 100]"
  >
  </mat-paginator>
</div>
