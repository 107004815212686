import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-activity-comment',
  templateUrl: './edit-activity-comment.component.html',
  styleUrls: ['./edit-activity-comment.component.scss']
})
export class EditActivityCommentComponent implements OnInit {

  initialComment: string;
  commentGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {comment: string},
    private dialogRef: MatDialogRef<EditActivityCommentComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initialComment = this.data.comment;
    this.commentGroup = this.fb.group({ comment: this.data.comment })
  }

  onSave() {
    this.dialogRef.close({ data: { comment: this.commentGroup.value.comment } });
  }

  onClose() {
    this.dialogRef.close();
  }
}
