import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MutationResult } from 'apollo-angular';

import {
  GetMetricNextTaskGQL,
  GetDashboardsGQL,
  GetDashboardsListGQL,
  GetMetricTableCardContentGQL,
  GetMetricTableCardNextTaskUpdateGQL,
  SetMetricValueNextTaskMutationGQL,
  SetMetricValueNextTaskL2MutationGQL,
  UpdateMetricValueCommentMutationGQL,
  UpdateMetricValueImportanceMutationGQL,
  SetMetricValueNextTaskMutationMutation,
  SetMetricValueNextTaskL2MutationMutation,
  GetMetricCommentsGQL,
  CreateCustomLinkGQL,
  CreateCustomLinkInput,
  DashboardType,
  GetMetricValuesEscalatedGQL,
  EscalateMetricValueMutationGQL,
  UpdateDashboardCardGQL,
  UpdateDashboardCardInput,
  GetWeeklyBarchartCardContentGQL,
  GetPromisesTasksGQL,
  GetOrderCandidateStatsGQL,
  AggregatedMetricsGQL,
  GetMetricGroupsByDashboardCardGQL,
  MetricValueCommentTypeConnection,
  GetUsersForCurrentAndChildDashboardsGQL
} from '../../../generated/graphql';
import { ReasonChipsData } from '../../shared/reason-with-comment/reason-with-comment.component';

export const SELECTED_DASHBOARD_KEY = 'selected_dashboard';

@Injectable({
  providedIn: 'root'
})
export class StandupDashboardServiceService {

  constructor(
    private getMetricComments: GetMetricCommentsGQL,
    private getMetricNextTask: GetMetricNextTaskGQL,
    private getAllDashboards: GetDashboardsGQL,
    private getAllDashboardsList: GetDashboardsListGQL,
    private getMetricTableValuesGQL: GetMetricTableCardContentGQL,
    private getMetricGroupsByCardGQL: GetMetricGroupsByDashboardCardGQL,
    private getWeeklyBarchartCardContentGQL: GetWeeklyBarchartCardContentGQL,
    private getMetricValuesEscalatedGQL: GetMetricValuesEscalatedGQL,
    private setMetricValueNextTaskMutationGQL: SetMetricValueNextTaskMutationGQL,
    private setMetricValueNextTaskL2MutationGQL: SetMetricValueNextTaskL2MutationGQL,
    private getMetricTableCardNextTaskUpdateGQL: GetMetricTableCardNextTaskUpdateGQL,
    private updateMetricValueCommentMutationGQL: UpdateMetricValueCommentMutationGQL,
    private updateMetricValueImportanceMutationsGQL: UpdateMetricValueImportanceMutationGQL,
    private escalateMetricValueMutationGQL: EscalateMetricValueMutationGQL,
    private createCustomLinkGQL: CreateCustomLinkGQL,
    private updateDashboardCardGQL: UpdateDashboardCardGQL,
    private getPromisesTasksGQL: GetPromisesTasksGQL,
    private orderCandidateStatsGql: GetOrderCandidateStatsGQL,
    private getAggregatedMetricsGQL: AggregatedMetricsGQL,
    private getUsersForCurrentAndChildDashboards: GetUsersForCurrentAndChildDashboardsGQL
  ) { }

  getDashboards(): Observable<DashboardType[]> {
    return this.getAllDashboards.watch().valueChanges.pipe(
      map(result => {
        return result.data.allDashboards.edges.map(edge => edge.node)
          .sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)) as DashboardType[];
      })
    );
  }

  getDashboardsList() {
    return this.getAllDashboardsList.watch().valueChanges.pipe(
      map(result => result.data.allDashboards.edges.map(edge => edge.node))
    );
  }

  getMetricGroupsByDashboardCard(cardId: string) {
    return this.getMetricGroupsByCardGQL.watch(
      { cardId },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => result.data.metricGroupsAssignment.edges.map(edge => edge.node))
    );
  }

  getMetricTableValues(id: string, dashboardId: string, dateFrom?: string, dateTo?: string) {
    return this.getMetricTableValuesGQL.watch(
      { id, dashboardId, dateFrom, dateTo },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => result.data.metricGroupsAssignment.edges.map(edge => edge.node))
    );
  }

  getWeeklyBarchartValues(id: string, dashboardId: string, dateFrom?: string, dateTo?: string) {
    return this.getWeeklyBarchartCardContentGQL.watch(
      { id, dashboardId, dateFrom, dateTo },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => result.data.metricGroupsAssignment.edges.map(edge => edge.node))
    );
  }

  getMetricValuesEscalated(escalatedTo: string, dashboardId: string, dateFrom?: string, dateTo?: string) {
    return this.getMetricValuesEscalatedGQL.watch(
      { escalatedTo, dateFrom, dateTo },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => {
        const data = [];
        result.data.metricValues.edges.forEach(edge => {
          let groupObj = data.find(item => item.id === edge.node.metricGroup.id);
          if (!groupObj) {
            groupObj = {
              metricGroup: {
                ...edge.node.metricGroup,
                metricvalueSet: {
                  edges: []
                }
              }
            };
            data.push(groupObj);
          }

          groupObj.metricGroup.metricvalueSet.edges.push({
            node: {
              id: edge.node.id,
              candidate: edge.node.candidate,
              client: edge.node.client,
              date: edge.node.date,
              goal: edge.node.goal,
              isImportant: edge.node.isImportant,
              manager: edge.node.manager,
              metricGroup: edge.node.metricGroup,
              metricvaluecommentSet: edge.node.metricvaluecommentSet,
              nextTaskId: edge.node.nextTaskId,
              nextTaskLevel2Id: edge.node.nextTaskLevel2Id,
              order: edge.node.order,
              value: edge.node.value,
              workis: edge.node.workis,
              escalatedTo: edge.node.escalatedTo,
              fromEscalated: true
            }
          });
        });

        return data;
      })
    );
  }

  getTasksByIdQuery(IDs: string[]) {
    return this.getMetricNextTask.watch(
      { ids: IDs },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => result.data)
    );
  }

  getCommentsByIdQuery(IDs: string[]) {
    return this.getMetricComments.watch(
      { ids: IDs },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => result.data)
    );
  }

  getUpdatedTask(id: string) {
    return this.getMetricTableCardNextTaskUpdateGQL.watch( { id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(result => result.data.tasks.edges[0].node)
    );
  }

  setMetricValueNextTask(id: string, taskId: string, refetchQueries?: string[])
    : Observable<MutationResult<SetMetricValueNextTaskMutationMutation>>
  {
    return this.setMetricValueNextTaskMutationGQL.mutate({ id, taskId }, { refetchQueries });
  }

  createCustomLink(input: CreateCustomLinkInput) {
    return this.createCustomLinkGQL.mutate({ input });
  }

  setMetricValueNextTaskL2(id: string, taskId: string, refetchQueries?: string[])
    : Observable<MutationResult<SetMetricValueNextTaskL2MutationMutation>>
  {
    return this.setMetricValueNextTaskL2MutationGQL.mutate({ id, taskId }, { refetchQueries });
  }

  updateMetricValueComment(id: string, comment: string) {
    return this.updateMetricValueCommentMutationGQL.mutate({ id, comment });
  }

  updateMetricValueImportance(id: string, isImportant: boolean) {
    return this.updateMetricValueImportanceMutationsGQL.mutate({ id, isImportant });
  }

  escalateMetricValue(input: { id: string, escalatedTo: string, escalatedFrom: string }) {
    return this.escalateMetricValueMutationGQL.mutate({ input });
  }

  storeSelectedDashboard(dashboardId: string) {
    localStorage.setItem(SELECTED_DASHBOARD_KEY, dashboardId);
  }

  getStoredSelectedDashboard() {
    return localStorage.getItem(SELECTED_DASHBOARD_KEY);
  }

  updateDashboardCard(input: UpdateDashboardCardInput) {
    return this.updateDashboardCardGQL.mutate({ input });
  }

  getPromisesTasks(input: { categoriesKeys: string, owners: any[], date_Gte: string, excludeStatuses: string }) {
    return this.getPromisesTasksGQL.fetch(input);
  }

  getOrderCandidateStats(input: { orderIds: string[], dateFrom: string, companyIds: string[] }) {
    return this.orderCandidateStatsGql.fetch(input);
  }

  getAggregatedMetrics(input: { regionGroupId: string, dateFrom: string, dateTo: string, metricGroupIds: string[] }) {
    return this.getAggregatedMetricsGQL.fetch(input, { fetchPolicy: 'no-cache' });
  }

  getUsersForCurrentChildDashboards(parentDashboardId: string) {
    return this.getUsersForCurrentAndChildDashboards.fetch({ parentDashboardId }, { fetchPolicy: 'no-cache' });
  }

  groupCommentsByReasonSet(set: MetricValueCommentTypeConnection): ReasonChipsData[] {
    const grouped: ReasonChipsData[] = [];
    set.edges.forEach(({ node }) => {
      const existReason = grouped.find(reason => reason.metricClientReason?.id === node.metricClientReason?.id);

      if (existReason) {
        existReason.multiplier = existReason.multiplier + 1;
        existReason.reasonGroup.push({ ...node });
      } else {
        grouped.push({ ...node, multiplier: 1, reasonGroup: [{ ...node }] });
      }
    });

    return grouped;
  }
}
