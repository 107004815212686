import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

import {
  CreateOperationsTaskGQL,
  CreateOperationsTaskInput,
  DeleteTaskGQL,
  GetNextTaskDateGQL,
  GetOperationsTaskGQL,
  GetOperationsTasksGQL,
  GetOverdueTasksGQL,
  GetMeetingTasksGQL,
  GetTaskCustomFieldValuesAndCategoriesGQL,
  TaskCustomFieldUpdateValuesGQL,
  UpdateOperationsTaskGQL,
  UpdateOperationsTaskInput,
  CreateMetricValueInput,
  CreateMetricValueCommentInput,
  UpdateOperationsTaskMutationVariables,
  TaskType,
  GetOperationsTaskQuery,
  CreateMetricValueInputGQL,
  CreateMetricValueCommentGQL,
  GetDynamicDashboardTasksGQL,
  GetCustomFieldValuesByTasksGQL
} from '../../generated/graphql';
import { NotificationService } from '../shared/notification.service';
import {
  ScheduledTaskCreateNotificationComponent
} from './planner-table/scheduled-task-create-notification/scheduled-task-create-notification.component';

@Injectable({
  providedIn: 'root'
})
export class TaskServiceService {
  // customFieldSavedValue;

  constructor(
    private operationTasksGQL: GetOperationsTasksGQL,
    private updateOperationTaskGQL: UpdateOperationsTaskGQL,
    private createMetricValueInputGQL: CreateMetricValueInputGQL,
    private createMetricValueCommentGQL: CreateMetricValueCommentGQL,
    private createOperationTaskGQL: CreateOperationsTaskGQL,
    private createUpdateCustomFieldsGQL: TaskCustomFieldUpdateValuesGQL,
    private getTaskGQL: GetOperationsTaskGQL,
    private getOverdueTasksGQL: GetOverdueTasksGQL,
    private getMeetingTasksGQL: GetMeetingTasksGQL,
    private getDynamicDashboardTasksGQL: GetDynamicDashboardTasksGQL,
    private getCustomFieldValuesAndCategories: GetTaskCustomFieldValuesAndCategoriesGQL,
    private getNextTaskDateGQL: GetNextTaskDateGQL,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private deleteTaskGQL: DeleteTaskGQL,
    private getCustomFieldValuesByTasksGQL: GetCustomFieldValuesByTasksGQL
  ) { }

  getTasks(params?: any) {
    if (!params) {
      params = { orderBy: '-date' };
    } else if (!params.orderBy) {
      params.orderBy = '-date';
    }

    return this.operationTasksGQL.watch(params).valueChanges;
  }

  // setCustomPlanValue(val: number) {
  //   if (val >= 0) {
  //     this.customFieldSavedValue = val;
  //   }
  // }
  //
  // getCustomPlanValue() {
  //   return this.customFieldSavedValue;
  // }

  deleteTask(taskId: string, refetchQueries?: string[]) {
    return this.deleteTaskGQL.mutate({
      taskId
    }, { refetchQueries });
  }

  getOverdueTasks(
    dashboardId: any,
    categoriesKeys: string,
    excludeCategoriesKeys: string,
    excludeStatuses: string,
    overdueOnly?: boolean
  ) {
    const queryInput: { [key: string]: any } = {
      dashboardId,
      orderBy: 'date',
      statuses: 'IN_PROGRESS,PLANNED,ANNOUNCE',
      categoriesKeys,
      excludeCategoriesKeys,
      excludeStatuses
    };

    if (overdueOnly) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      queryInput.date_Lte = yesterday.toLocaleDateString('lt-LT');
    }

    return this.getOverdueTasksGQL.fetch(queryInput, { fetchPolicy: 'no-cache' });
  }

  getMeetingTasks(
    dashboardId: any,
    meetingOnly: boolean,
    dashboardMembers: any,
    categories: string[],
    categoriesKeys: string,
    excludeCategoriesKeys: string,
    excludeStatuses: string,
    includeChildDashboards: boolean
  ) {
    const queryInput: { [key: string]: any } = {
      dashboardId,
      statuses: 'PLANNED,ANNOUNCE',
      category: categories,
      categoriesKeys,
      excludeCategoriesKeys,
      excludeStatuses
    };

    // commented 08/01/2025
    // Slack message - https://biurobaltic.slack.com/archives/C065ZV3J77D/p1736347733352789

    // if (meetingOnly) {
    //   queryInput.owners = dashboardMembers.map((member) => member.id);
    // }

    if (includeChildDashboards) {
      queryInput.includeChildDashboards = true;
    }

    return this.getMeetingTasksGQL.fetch(queryInput, { fetchPolicy: 'no-cache' });
  }

  getDynamicDashboardTasks(
    dashboardId: any,
    categoriesKeys: string,
    excludeCategoriesKeys: string,
    excludeStatuses: string,
    includeChildDashboards: boolean,
    lookForwardDays: number,
    customFields: string
  ) {
    const queryInput: { [key: string]: any } = {
      dashboardId,
      categoriesKeys,
      excludeCategoriesKeys,
      excludeStatuses,
      customFields
    };

    if (lookForwardDays !== undefined && lookForwardDays !== null) {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + lookForwardDays);
      queryInput.date_Lte = futureDate.toLocaleDateString('lt-LT');
    }

    if (includeChildDashboards) {
      queryInput.includeChildDashboards = true;
    }

    return this.getDynamicDashboardTasksGQL.fetch(queryInput, { fetchPolicy: 'no-cache' });
  }

  getDynamicDashboardCustomValues(input: { taskIds: string[], keys: string }) {
    return this.getCustomFieldValuesByTasksGQL.fetch(input, { fetchPolicy: 'no-cache' });
  }

  updateTask(task: UpdateOperationsTaskInput, refetchQueries?: string[]) {
    return this.updateOperationTaskGQL.mutate({
      taskId: task.id,
      status: task.status,
      order: task.order,
      company: task.company,
      category: task.category,
      date: task.date,
      comment: task.comment,
      completedBy: task.completedBy,
      durationMinutes: task.durationMinutes,
      country: task.country,
      dashboard: task.dashboard,
      nextTaskAssignee: task.nextTaskAssignee
    }, { refetchQueries });
  }

  createMetricValue(value: CreateMetricValueInput, refetchQueries?: string[]) {
    return this.createMetricValueInputGQL.mutate({
      ...value
    }, { refetchQueries });
  }

  createMetricValueComment(commentBody: CreateMetricValueCommentInput) {
    return this.createMetricValueCommentGQL.mutate({
      ...commentBody
    });
  }

  updateTaskPartial(task: UpdateOperationsTaskMutationVariables, refetchQueries?: string[]):
    Observable<{ data: { updateOperationsTask: { task: TaskType } } }>
  {
    return this.updateOperationTaskGQL.mutate(task, { refetchQueries }) as
      Observable<{ data: { updateOperationsTask: { task: TaskType } } }>;
  }

  createTask(task: CreateOperationsTaskInput, refetchQueries?: string[]) {
    return this.createOperationTaskGQL.mutate({
      ...task
      // duration: task.duration
    }, { refetchQueries });
  }

  getTask(taskId: string): Observable<ApolloQueryResult<GetOperationsTaskQuery>> {
    return this.getTaskGQL.fetch({ id: taskId });
  }

  getNextTaskDate(taskId: string) {
    return this.getNextTaskDateGQL.fetch({id: taskId});
  }

  getTaskCustomFieldValuesAndCategories(taskId: string) {
    return this.getCustomFieldValuesAndCategories.fetch({ taskId }, { fetchPolicy: 'no-cache' });
  }

  saveTaskCustomFieldValues(taskId, customFieldValues) {
    customFieldValues = customFieldValues.map(customFieldValue => {
      if (customFieldValue.dateValue) {
        customFieldValue.dateValue = new Date(customFieldValue.dateValue).toISOString().substring(0, 10);
      }
      return customFieldValue;
    });

    return this.createUpdateCustomFieldsGQL.mutate({
      taskId,
      customFieldValues
    });
  }

  callScheduledTaskCreateMessage(data: any) {
    const task = data.data.updateOperationsTask.task;
    const edges = task?.taskSet.edges || [];
    if (edges?.length) {
      this.notifyAboutScheduledTaskCreate(edges);
    } else if ((data.data.updateOperationsTask.task.schedules.edges.length || task.createdBySchedule) && task.status === 'DONE') {
      this.notifyAboutScheduledTaskDontCreate(task.schedules.edges[0]?.node.endDate || task.createdBySchedule?.endDate || 'Unknown');
    }
  }

  notifyAboutScheduledTaskCreate(edges: any) {
    this.notificationService.notify(
      '',
      0,
      10000,
      {component: ScheduledTaskCreateNotificationComponent, data: {
        date: edges[0].node.date,
        link: `${location.origin}/planner/${encodeURIComponent(edges[0].node.id)}`
      }}
    );
  }

  notifyAboutScheduledTaskDontCreate(date: string) {
    this.notificationService.notify(
      this.translate.instant('TASK_WAS_NOT_CREATED_MESSAGE', {date}),
      1,
      5000
    );
  }
}
