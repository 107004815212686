import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from '../app.component';
import { PmStatsDashboardRoutingModule } from './pm-stats-dashboard-routing.module';
import { PmStatsDashboardComponent } from './pm-stats-dashboard.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { DemandChartComponent } from './demand-chart/demand-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from '../shared/shared.module';
import { CustomfieldsModule } from '../customfields/customfields.module';
import { DailyCardComponent } from './daily-card/daily-card.component';
import {OrdersModule} from '../orders/orders.module';
import { TranslateModule } from '@ngx-translate/core';
import { StandupDashboardComponent } from './standup-boards/standup-dashboard/standup-dashboard.component';
import { MetricTableComponent } from './standup-boards/metric-table/metric-table.component';
import {
  TaskInlineCategoryComponent
} from './standup-boards/metric-table/task-inline-category/task-inline-category.component';
import {
  MetricReasonInlineComponent
} from './standup-boards/metric-table/metric-reason-inline/metric-reason-inline.component';
import { PlannerModule } from '../planner/planner.module';
import { OverdueTasksTableComponent } from './standup-boards/overdue-tasks-table/overdue-tasks-table.component';
import { TaskMultipleCreateDialogComponent } from './standup-boards/task-multiple-create-dialog/task-multiple-create-dialog.component';
import { MeetingClientComponent } from './standup-boards/meeting-client/meeting-client.component';
import { ProblemDialogComponent } from './standup-boards/metric-table/problem-dialog/problem-dialog.component';
import { DynamicDashboardTasksTableComponent } from './standup-boards/dynamic-dashboard-tasks-table/dynamic-dashboard-tasks-table.component';
import { StandupLinksComponent } from './standup-boards/standup-links/standup-links.component';
import { LinkCreateDialogComponent } from './standup-boards/standup-links/link-create-dialog/link-create-dialog.component';
import { WeeklyBarchartCardComponent } from './standup-boards/weekly-barchart-card/weekly-barchart-card.component';
import { NgChartsModule } from 'ng2-charts';
import { PromisesTableComponent } from './standup-boards/promises-table/promises-table.component';
import {
  AggregatedMetricsTableComponent
} from './standup-boards/aggregated-metrics-table/aggregated-metrics-table.component';
import { NotAggregatedTableComponent } from './standup-boards/not-aggregated-table/not-aggregated-table.component';

@NgModule({
  declarations: [
    AggregatedMetricsTableComponent,
    PmStatsDashboardComponent,
    DemandChartComponent,
    DailyCardComponent,
    StandupDashboardComponent,
    MetricTableComponent,
    TaskInlineCategoryComponent,
    MetricReasonInlineComponent,
    OverdueTasksTableComponent,
    TaskMultipleCreateDialogComponent,
    MeetingClientComponent,
    ProblemDialogComponent,
    DynamicDashboardTasksTableComponent,
    StandupLinksComponent,
    LinkCreateDialogComponent,
    WeeklyBarchartCardComponent,
    PromisesTableComponent,
    NotAggregatedTableComponent
  ],
  imports: [
    BrowserModule,
    AppMaterialModule,
    PmStatsDashboardRoutingModule,
    NgxChartsModule,
    SharedModule,
    OrdersModule,
    TranslateModule,
    PlannerModule,
    CustomfieldsModule,
    NgChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class PmStatsDashboardModule { }
