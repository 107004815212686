import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { UploadOutput } from 'ngx-uploader';
import moment, { Moment } from 'moment';
import { Observable, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

import { ToolbarService } from '../navigation/toolbar.service';
import { SidenavService } from '../shared/sidenav.service';
import { EmployeeWorkedTimeDataResp, ExcelWidgetService, WorkedHoursByShiftCrateUpdateInput } from './excel-widget.service';
import { CompanySelectorComponent } from '../shared/company-selector/company-selector.component';
import { AbsencesService } from '../absences/absences.service';
import { ShiftParamsDialogComponent } from './shift-params-dialog/shift-params-dialog.component';
import { ExcelWidgetDatasource } from './excel-widget.datasource';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const defaultSelectionPoints = {
  nameColumn: {
    title: 'Name column',
    valueEx: null,
    valueExColIdx: null
  },
  employeeId: {
    title: 'Employee Id',
    valueEx: null,
    valueExColIdx: null
  },
  totalHours: {
    title: 'Total hours',
    valueEx: null,
    valueExColIdx: null
  },
  totalHoursDay: {
    title: 'TH day',
    valueEx: null,
    valueExColIdx: null
  },
  totalHoursNight: {
    title: 'TH night',
    valueEx: null,
    valueExColIdx: null
  },
  overtimeDay: {
    title: 'Overtime day',
    valueEx: null,
    valueExColIdx: null
  },
  overtimeNight: {
    title: 'Overtime night',
    valueEx: null,
    valueExColIdx: null
  },
  holidayDay: {
    title: 'Holiday day',
    valueEx: null,
    valueExColIdx: null
  },
  holidayNight: {
    title: 'Holidays night',
    valueEx: null,
    valueExColIdx: null
  },
  addDay: {
    title: 'First day',
    valueEx: { },
    valueExColIdxs: []
  }
};

export const EXCEL_WIDGET_LAST_USE_CONFIG_KEY = 'excel_widget_last_use_config';

export interface ExcelWidgetLastUseConfigModel {
  clientId?: string;
  yearMonth?: string;
  headerRowIdx?: number;
  selectedColumns?: { [key: string]: number };
}

@Component({
  selector: 'app-excel-widget',
  templateUrl: './excel-widget.component.html',
  styleUrls: ['./excel-widget.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExcelWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  isNumberValidator = /^(?:\d+|\d+\.\d+)$/;
  isDateHeaderValidator = /^\d{2}\/\d{2}$/;
  timeValidator = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
  Number = Number;
  String = String;
  moment = moment;
  console = console;

  encodedTypesDisplayedColumns = ['type', 'noShow', 'workFrom', 'workTo', 'lunchFrom', 'lunchTo', 'hrsDay', 'hrsNight', 'overtimeDay', 'overtimeNight', 'holidaysDay', 'holidaysNight'];
  encodedTypesDataSource: any;
  encodedTypesDataSourceDef = [];
  encodedTypesFormDict: any = { };
  isFirstTableBuild = true;
  isEncodedTypeReady = false;

  isEmployeeTableReady = false;
  isFullDimeDataLoaded = false;
  hideBuildAndUnselected = false;
  sheetNames: string[];
  sheetsData;
  processedColumn;
  processedRow;
  displayedColumns: string[] = [];
  staticColumns = ['numeric_key', 'fetchedId_key', 'days_worked', 'days_absence'];
  dataSource: ExcelWidgetDatasource;
  totalValuesByEmployee = { };

  selectionPoints = JSON.parse(JSON.stringify(defaultSelectionPoints));
  selectionPointsTitles = [
    'nameColumn', 'employeeId', 'totalHours', 'totalHoursDay', 'totalHoursNight', 'overtimeDay', 'overtimeNight', 'holidayDay',
    'holidayNight', 'addDay'
  ];
  secondSelectionPoints = ['totalHours', 'totalHoursDay', 'totalHoursNight', 'overtimeDay', 'overtimeNight', 'holidayDay', 'holidayNight'];
  selectionInProgress: string;

  searchForm: FormGroup;
  excludeRowsSelection = new SelectionModel<number>(true, []);
  currentColHovered = undefined;
  currentRowHovered = undefined;
  headerRowIdx = undefined;
  headerRow;
  hiddenColumnsIdx = [];
  shiftsData = [];
  workedHoursMatrix = { };
  timeManagementMatrix: { [key: string]: { [key: string]: '0' | '1' } } = { };
  lockTypetMatrix: { [key: string]: { [key: string]: any } } = { };
  savedConfig: ExcelWidgetLastUseConfigModel = { };
  subscription = new Subscription();
  absencesMatrix: { [key: string]: { [key: string]: { absenceType: string; absenceUnpaid: number; } } } = { };
  workedAbsenceDaysData: { [key: string]: { absenceDays?: number; workedDay?: number; } } = { };
  isAbsencesMatrixReady = false;
  isMainDataLoading = false;
  availableToSelectLength = 0;

  // Main table - cells selection
  isMouseDown = false;
  selectionRow = undefined;
  selectedRows = [];
  selectedUsers = [];
  selectedCells = [];
  unblockClickAction;

  paginationSub: Subscription;

  @ViewChild('sheetOptionsMenuTrigger') sheetOptionsMenuTrigger: MatMenuTrigger;
  @ViewChild('companySelector') companySelector: CompanySelectorComponent;
  @ViewChild('datepicker') datepicker: MatDatepicker<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return false;
  }

  get companyCtrl() { return this.searchForm.get('company') as FormControl; }
  get dateCtrl() { return this.searchForm.get('date') as FormControl; }

  constructor(
    private toolbarService: ToolbarService,
    private sidenav: SidenavService,
    private fb: FormBuilder,
    private excelWidgetService: ExcelWidgetService,
    private absencesService: AbsencesService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {
    this.toolbarService.hide();
    this.sidenav.close();
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      company: [null, Validators.required],
      date: [null, Validators.required]
    });
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      const savedConfig = this.readConfigFromLS();

      if (savedConfig) {
        this.savedConfig = savedConfig;
      }

      if (savedConfig?.yearMonth) {
        this.datepicker.select(moment(savedConfig.yearMonth));
      }

      if (savedConfig?.clientId) {
        this.companySelector.selectedCompanyById = savedConfig.clientId;
      }
    }, 0);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectHeaderRow(rowIdx: number, rowValue: any): void {
    this.excludeRowsSelection.clear();
    this.selectionInProgress = '';
    this.headerRowIdx = rowIdx;
    this.headerRow = rowValue;

    if (this.selectionPoints.employeeId.valueExColIdx) {
      this.selectionInProgress = 'employeeId';
      this.selectColumn(this.selectionPoints.employeeId.valueExColIdx);
    }

    this.saveConfigToLS('headerRowIdx', this.headerRowIdx);
  }

  selectColumn(colIdx: number): void {
    if (this.headerRow && this.selectionInProgress) {
      if (this.selectionInProgress === 'addDay') {
        this.selectionPoints.addDay.valueEx = { };
        this.selectionPoints.addDay.valueExColIdxs = [];
        const numOfDaysInMonth = moment(this.searchForm.controls.date?.value).daysInMonth();
        for (let i = 0; i < numOfDaysInMonth; i++) {
          const headKey = this.headerRow['generic_empty_key_' + (colIdx + i - (this.staticColumns.length - 1))];
          this.selectionPoints.addDay.valueEx[headKey] = this.getColumnValueByIdx(colIdx + i)
            .map(item => item === undefined || item === null ? '-' : item);
          this.selectionPoints.addDay.valueExColIdxs.push(colIdx + i);
        }
      } else {
        this.selectionPoints[this.selectionInProgress].valueEx = this.getColumnValueByIdx(colIdx);
        this.selectionPoints[this.selectionInProgress].valueExColIdx = colIdx;

        if (this.selectionInProgress === 'nameColumn') {
          this.selectionPoints[this.selectionInProgress].valueEx =
            this.selectionPoints[this.selectionInProgress].valueEx.map(item => item.trim());
          this.calculateInvalidEmployeeNames();
        }

        if (this.selectionInProgress === 'employeeId') {
          this.calculateInvalidEmployeeIds();
        }
      }

      this.saveConfigToLS('selectedColumns', { ...this.savedConfig.selectedColumns, [this.selectionInProgress]: colIdx });

      this.selectionInProgress = null;
      const selectedColumns = [];
      this.selectionPointsTitles.forEach(key => {
        if (key !== 'addDay') {
          selectedColumns.push(this.selectionPoints[key].valueExColIdx);
        } else {
          selectedColumns.push(...this.selectionPoints[key].valueExColIdxs);
        }
      });

      this.hiddenColumnsIdx = [];
      this.displayedColumns.forEach((_, idx) => {
        if (idx > 3 && !selectedColumns.includes(idx)) {
          this.hiddenColumnsIdx.push(idx);
        }
      });
    }
  }

  deselectColumn(columnKey: string): void {
    const colIdxs = [this.selectionPoints[columnKey].valueExColIdx] || [...this.selectionPoints[columnKey].valueExColIdxs];

    if (columnKey !== 'addDay') {
      this.selectionPoints[columnKey].valueEx = null;
      this.selectionPoints[columnKey].valueExColIdx = null;
    } else {
      this.selectionPoints[columnKey].valueEx = { };
      this.selectionPoints[columnKey].valueExColIdxs = [];
    }

    colIdxs.forEach(idx => {
      if (!this.hiddenColumnsIdx.includes(idx)) {
        this.hiddenColumnsIdx.push(idx);
      }
    });

    const columnsConfig = { ...this.savedConfig.selectedColumns };
    delete columnsConfig[columnKey];
    this.saveConfigToLS('selectedColumns', columnsConfig);
  }

  calculateInvalidEmployeeNames() {
    const newArr = [];
    this.selectionPoints.nameColumn.valueEx = this.selectionPoints.nameColumn.valueEx.map((item, i) => {
      if (item && !newArr.includes(item)) {
        newArr.push(item);
        return item;
      }
      if (!this.excludeRowsSelection.isSelected(this.headerRowIdx + i + 1)) {
        this.excludeRowsSelection.toggle(this.headerRowIdx + i + 1);
      }
      return null;
    });
  }

  calculateInvalidEmployeeIds() {
    const newArr = [];
    this.selectionPoints.employeeId.valueEx = this.selectionPoints.employeeId.valueEx.map((item, i) => {
      if (Number(item) && !newArr.includes(item)) {
        newArr.push(item);
        return item;
      }
      if (!this.excludeRowsSelection.isSelected(this.headerRowIdx + i + 1)) {
        this.excludeRowsSelection.toggle(this.headerRowIdx + i + 1);
      }
      return null;
    });
  }

  isEmployeeNameOrIdError(columnIdx: number, rowIdx: number): boolean {
    const idErr = this.selectionPoints.employeeId.valueExColIdx === columnIdx
              && !this.getEmployeeIdByRowIdx(rowIdx) && (this.paginator.pageIndex > 0 || rowIdx > this.headerRowIdx);
    const nameErr = this.selectionPoints.nameColumn.valueExColIdx === columnIdx
                && !this.getEmployeeNameByRowIdx(rowIdx) && (this.paginator.pageIndex > 0 || rowIdx > this.headerRowIdx);
    return idErr || nameErr;
  }

  isEmployeeLocked(columnIdx: number, rowIdx: number): boolean {
    if (Object.keys(this.lockTypetMatrix).length > 0) {
      const data = this.getEmployeeShiftData(columnIdx, rowIdx);
      if (data) {
        const shiftId = data.shift_id;
        const pureIdx = this.getEmployeeIdByRowIdx(rowIdx);
        if (shiftId && pureIdx) {
          if (this.lockTypetMatrix[pureIdx] && this.lockTypetMatrix[pureIdx][shiftId] === 'confirmed') {
            return true;
          }
        }
      }
      return false;
    }
  }

  generateComparingTable(isHardReload?: boolean): void {
    this.isMainDataLoading = true;
    const dayColumns = Object.keys(this.selectionPoints.addDay.valueEx);
    const startRowIdx = this.headerRowIdx;
    this.encodedTypesDataSource = new TableVirtualScrollDataSource([]);
    this.encodedTypesFormDict = { };
    const companyId = this.companyCtrl.value.livasId;
    const companyCountry = this.companyCtrl.value.country.isoCode;
    const dateFrom = moment(this.dateCtrl.value).startOf('month').format('YYYY-MM-DD');
    const dateTo = moment(this.dateCtrl.value).endOf('month').format('YYYY-MM-DD');

    if (!this.selectionPoints.employeeId.valueExColIdx || (!isHardReload && this.selectionPoints.employeeId.valueExColIdx === 1)) {
      const fullNames = this.selectionPoints.nameColumn.valueEx?.filter(item => item);
      const month = moment(this.dateCtrl.value).format('YYYY-MM');
      this.selectionPoints.employeeId.valueExColIdx = 1;
      if (isHardReload) {
        this.selectionPoints.employeeId.valueEx = [];
      }
      this.excelWidgetService.getEmployeesByNames({ fullNames, month, companyId }, companyCountry).subscribe(
        res => {
          this.selectionPoints.nameColumn.valueEx.forEach((name, i) => {
            if (name && res[name]) {
              this.selectionPoints.employeeId.valueEx[i] = res[name].employeesIds[0];
            } else if (isHardReload) {
              this.selectionPoints.employeeId.valueEx[i] = null;
            }
          });

          // const employeeIds = this.selectionPoints.employeeId.valueEx?.filter(item => item);
          const employeeIds = this.getEmployeeIdsFromCurrentPaginationPage().ids;
          if (employeeIds.length) {
            this.excelWidgetService.getEmployeesWorkedTime({ employeeIds, dateFrom, dateTo, companyId }, companyCountry).subscribe(
              res2 => this.configureShiftsData(res2, isHardReload), () => { }
            );
          } else {
            this.isMainDataLoading = false;
          }
        },
        err => {
          this.openErrorSnackbar(err);
          this.isMainDataLoading = false;
        }
      );
    } else {
      // const employeeIds = this.selectionPoints.employeeId.valueEx?.filter(item => item);
      const employeeIds = this.getEmployeeIdsFromCurrentPaginationPage().ids;
      this.excelWidgetService.getEmployeesWorkedTime({ employeeIds, dateFrom, dateTo, companyId }, companyCountry).subscribe(
        res => this.configureShiftsData(res, isHardReload),
        err => {
          this.openErrorSnackbar(err);
          this.isMainDataLoading = false;
        }
      );
    }

    dayColumns.forEach((column, abIdx) => {
      this.selectionPoints.addDay.valueEx[column].forEach((value, idx) => {
        if (value && value !== '-' && !this.excludeRowsSelection.isSelected(idx  + startRowIdx + 1)) {
          const isDecodingKeyExist = this.encodedTypesDataSource.data.find(type => type === value);
          if (!isDecodingKeyExist) {
            const prefill = String(value).split('/');
            let hDay = 0;
            let hNight = 0;

            if (
              prefill.length === 2 && (typeof Number(prefill[0]) === 'number' && !isNaN(Number(prefill[0])))
              && (typeof Number(prefill[1]) === 'number' && !isNaN(Number(prefill[1])))
            ) {
              hDay = Number(prefill[0]);
              hNight = Number(prefill[1]);
            } else if (typeof value === 'number') {
              hDay = value;
            }

            this.encodedTypesFormDict[value] = this.fb.group({
              colIdx: abIdx,
              rowIdx: idx,
              noShow: [false],
              fromTo: this.fb.group({
                workFrom: [null, [Validators.required, Validators.pattern(this.timeValidator)]],
                workTo: [null, [Validators.required, Validators.pattern(this.timeValidator)]],
                lunchFrom: [null, [Validators.required, Validators.pattern(this.timeValidator)]],
                lunchTo: [null, [Validators.required, Validators.pattern(this.timeValidator)]]
              }),
              hoursSum: this.fb.group({
                hrsDay: [hDay, [Validators.required]],
                hrsNight: [hNight, [Validators.required]],
                overtimeDay: [0, [Validators.required]],
                overtimeNight: [0, [Validators.required]],
                holidaysDay: [0, [Validators.required]],
                holidaysNight: [0, [Validators.required]]
              }),
              decoded: [null]
            });

            this.encodedTypesFormDict[value].valueChanges.subscribe(() => {
              if (this.getManagementTypeByRelatedIdxs(abIdx, idx) === '0' && this.getDecodeGroupByKey(value).get('fromTo').valid) {
                const group = this.getDecodeGroupByKey(value);
                const { workFrom, workTo, lunchFrom, lunchTo } = group.get('fromTo').value;
                group.controls.decoded?.setValue((
                  (moment(`2000-01-0${workTo.replace(':', '.') < workFrom.replace(':', '.') ? '2' : '1'}T${workTo}:00`).diff(moment(`2000-01-01T${workFrom}:00`), 'minutes') -
                   moment(`2000-01-0${lunchTo.replace(':', '.') < lunchFrom.replace(':', '.') ? '2' : '1'}T${lunchTo}:00`).diff(moment(`2000-01-01T${lunchFrom}:00`), 'minutes')) / 60
                ).toFixed(2), { emitEvent: false });
              } else if (this.getManagementTypeByRelatedIdxs(abIdx, idx) === '1' && this.getDecodeGroupByKey(value).get('hoursSum').valid) {
                const group = this.getDecodeGroupByKey(value);
                const { hrsDay, hrsNight, overtimeDay, overtimeNight, holidaysDay, holidaysNight } = group.get('hoursSum').value;
                group.controls.decoded?.setValue(
                  (Number(hrsDay) + Number(hrsNight) + Number(overtimeDay) + Number(overtimeNight) + Number(holidaysDay)
                    + Number(holidaysNight)).toFixed(2), { emitEvent: false }
                );
              } else {
                this.getDecodeControlByGroupAndKeyGeneral(value, 'decoded').setValue(null, { emitEvent: false });
              }
            });

            this.encodedTypesDataSource.data.push(value);
          }
        }
      });
    });

    this.encodedTypesDataSourceDef = [...this.encodedTypesDataSource.data];
    this.hideBuildAndUnselected = true;
    this.isEmployeeTableReady = true;
  }

  configureShiftsData(data: EmployeeWorkedTimeDataResp, isHardReload?: boolean): void {
    this.shiftsData = [];
    this.selectionPoints.employeeId.valueEx.forEach((id) => {
      if (data[id]) {
        this.workedHoursMatrix[id] = { };
        this.timeManagementMatrix[id] = { };
        this.lockTypetMatrix[id] = { };
        const employeeShiftsByDates = { };
        data[id].forEach(({ date, shiftStatus, shift_id, totalWorked, total_planned_hours, time_management_type, workhours }) => {
          this.workedHoursMatrix[id][shift_id] = workhours;
          this.timeManagementMatrix[id][shift_id] = time_management_type;
          this.lockTypetMatrix[id][shift_id] = shiftStatus;
          employeeShiftsByDates[moment(date).format('DD/MM')] = { shift_id, totalWorked, total_planned_hours };
        });

        this.shiftsData.push(employeeShiftsByDates);
        this.workedAbsenceDaysData[id] = { workedDay: data[id].length };
      } else {
        if (isHardReload) {
          // this.selectionPoints.employeeId.valueEx[idx] = null;
        }
        this.shiftsData.push(undefined);
      }
    });

    const dateFrom = moment(this.dateCtrl.value).startOf('month').format('YYYY-MM-DD');
    const dateTo = moment(this.dateCtrl.value).endOf('month').format('YYYY-MM-DD');
    // const employeeIds = this.selectionPoints.employeeId.valueEx?.filter(id => id);
    const employeeIds = this.getEmployeeIdsFromCurrentPaginationPage().ids;
    const companyCountry = this.companyCtrl.value.country.isoCode;

    if (Object.keys(this.selectionPoints).filter(key => this.selectionPoints[key].valueExColIdx).length > 2) {
      const month = moment(this.dateCtrl.value).format('YYYY-MM');
      const companyId = this.companyCtrl.value.livasId;
      this.excelWidgetService.getMonthInfoByClient({ employeeIds, month, companyId, confirmed: 1 }, companyCountry).subscribe(
        res => {
          Object.keys(res)?.forEach(key => {
            this.totalValuesByEmployee[key] = {
              totalHoursDay: res[key].comfirmed?.day || res[key].saved?.day || 0,
              totalHoursNight: res[key].comfirmed?.night || res[key].saved?.night || 0,
              overtimeDay: res[key].comfirmed?.overtime_day || res[key].saved?.overtime_day || 0,
              overtimeNight: res[key].comfirmed?.overtime_night || res[key].saved?.overtime_night || 0,
              holidaysDay: res[key].comfirmed?.holiday_day || res[key].saved?.holiday_day || 0,
              holidaysNight: res[key].comfirmed?.holiday_night || res[key].saved?.holiday_night || 0
            };
            this.totalValuesByEmployee[key].totalHours = Object.keys(this.totalValuesByEmployee[key]).reduce(
              (acc: number, value: string ) => acc + this.totalValuesByEmployee[key][value], 0
            );
          });
          this.isMainDataLoading = false;
        },
        err => {
          this.openErrorSnackbar(err);
          this.isMainDataLoading = false;
        }
      );
      this.isFullDimeDataLoaded = true;
    } else {
      this.isMainDataLoading = false;
    }

    this.absencesService.getEmployeeAllAbsencesByRange(dateFrom, dateTo, employeeIds, companyCountry).subscribe(
      resp => {
        this.absencesMatrix = { };
        Object.keys(resp).forEach(key => {
          this.absencesMatrix[key] = { };
          resp[key].absences.forEach(dateItem =>
            this.absencesMatrix[key][moment(dateItem.date).format('DD/MM')] = {
              absenceType: dateItem.absence_type,
              absenceUnpaid: dateItem.absence_unpaid
            }
          );
        });
        Object.keys(resp).forEach(key => this.workedAbsenceDaysData[key].absenceDays = resp[key].absences.length);
        this.isAbsencesMatrixReady = true;
      },
      err => this.openErrorSnackbar(err)
    );

    this.isEncodedTypeReady = true;

    if (this.isFirstTableBuild && this.encodedTypesDisplayedColumns.length) {
      this.isFirstTableBuild = false;
      window.setTimeout(() => this.subscription.add(
        this.sort.sortChange.subscribe(sort => {
          if (sort.direction) {
            this.encodedTypesDataSource.data = [...this.encodedTypesDataSource.data.sort((a, b) => {
              if ((Number(a) || String(a).includes('/')) && !(Number(b) || String(b).includes('/'))) {
                return sort.direction === 'desc' ? 1 : -1;
              } else if (!(Number(a) || String(a).includes('/')) && (Number(b) || String(b).includes('/'))) {
                return sort.direction === 'desc' ? -1 : 1;
              } else {
                return 0;
              }
            })];
          } else {
            this.encodedTypesDataSource.data = [...this.encodedTypesDataSourceDef];
          }
        }),
      ), 0);
    }
  }

  setPaginatorObs() {
    if (this.paginationSub) {
      this.paginationSub.unsubscribe();
    }

    this.paginationSub = this.dataSource.needDataLoadEmitter.subscribe(() => {
      const { ids, pureRange } = this.getEmployeeIdsFromCurrentPaginationPage();

      if (ids?.length && this.shiftsData.length) {
        const pureIdxs = Array.from({ length: pureRange.end - pureRange.start }, (_, i) => pureRange.start + i);
        const idIdxMatches = { };
        ids.forEach((id, idx) => idIdxMatches[id] = pureIdxs[idx]);
        this.loadShiftsDataByEmployees(ids, idIdxMatches);
      }
    });
  }

  getEmployeeIdsFromCurrentPaginationPage() {
    const startDataIdx = this.paginator.pageIndex > 0
                          ? this.paginator.pageIndex * this.paginator.pageSize - this.headerRowIdx - 1 : 0;
    const endDataIdx = this.paginator.pageIndex > 0
                          ? this.getPureRowIdx(this.paginator.pageSize) : this.paginator.pageSize - this.headerRowIdx - 1;

    return {
      ids: this.selectionPoints.employeeId.valueEx?.slice(startDataIdx, endDataIdx),
      pureRange: { start: startDataIdx, end: endDataIdx }
    };
  }

  getDayValueMatch(i: number, j: number, colValue: any, managementType: boolean | '0' | '1'): boolean {
    const shiftData = this.getEmployeeShiftData(i, j);
    return shiftData && this.isDayCompareDisplay(i, j) && shiftData.totalWorked === (
      managementType === '1'
        ? (typeof colValue === 'number' || typeof colValue === 'string')
          ? this.getObjectNumericSum(this.encodedTypesFormDict[colValue]?.value.hoursSum)
          : false
        : Number(this.dayValueDisplayFn(colValue) || colValue)
    );
  }

  getDayValueUnmatch(i: number, j: number, colValue: any, managementType: boolean | '0' | '1'): boolean {
    const shiftData = this.getEmployeeShiftData(i, j);
    return shiftData && this.isDayCompareDisplay(i, j) && shiftData.totalWorked !== (
      managementType === '1'
        ? (typeof colValue === 'number' || typeof colValue === 'string')
          ? this.getObjectNumericSum(this.encodedTypesFormDict[colValue]?.value.hoursSum)
          : false
        : Number(this.dayValueDisplayFn(colValue) || colValue)
    );
  }

  getDayValueWarn(i: number, j: number, colValue: any): boolean {
    return this.isDayCompareDisplay(i, j) && ((!this.getEmployeeShiftData(i, j) && colValue)
           || (this.getEmployeeShiftData(i, j) && !colValue));
  }

  getTotalStatValueMatch(i: number, j: number, colValue: any, managementType: boolean | '0' | '1'): boolean {
    return this.isEmployeeTableReady && j > this.headerRowIdx && this.isFullDimeDataLoaded
      && this.isColumRelatedToOneOfSelections(this.secondSelectionPoints, i)
      && this.totalValuesByEmployee[this.getEmployeeIdByRowIdx(j)]?.[this.getColumnValueKeyByColIdx(i)].toFixed(4) === (
        managementType === '1'
          ? (typeof colValue === 'number' || typeof colValue === 'string')
            ? this.getObjectNumericSum(this.encodedTypesFormDict[colValue]?.value.hoursSum)
            : false
          : Number(colValue).toFixed(4)
      );
  }

  getTotalStatValueUnmatch(i: number, j: number, colValue: any, managementType: boolean | '0' | '1'): boolean {
    return this.isEmployeeTableReady && j > this.headerRowIdx && this.isFullDimeDataLoaded
      && this.isColumRelatedToOneOfSelections(this.secondSelectionPoints, i)
      && this.totalValuesByEmployee[this.getEmployeeIdByRowIdx(j)]?.[this.getColumnValueKeyByColIdx(i)].toFixed(4) !== (
        managementType === '1'
          ? (typeof colValue === 'number' || typeof colValue === 'string')
            ? this.getObjectNumericSum(this.encodedTypesFormDict[colValue]?.value.hoursSum)
            : false
          : Number(colValue).toFixed(4)
      );
  }

  getTotalStatValueWarn(i: number, j: number, colValue: any): boolean {
    const employeeTotal = this.totalValuesByEmployee[this.getEmployeeIdByRowIdx(j)]?.[this.getColumnValueKeyByColIdx(i)];
    return this.isEmployeeTableReady && j > this.headerRowIdx && this.isFullDimeDataLoaded
      && this.isColumRelatedToOneOfSelections(this.secondSelectionPoints, i)
      && ((!employeeTotal && employeeTotal !== 0) || (!colValue && colValue !== 0));
  }

  getColumnValueKeyByColIdx(colIdx: number): string {
    return Object.keys(this.selectionPoints).filter(key => this.selectionPoints[key].valueExColIdx === colIdx)[0];
  }

  getDecodeGroupByKey(key: string): FormGroup {
    return this.encodedTypesFormDict[key];
  }

  getDecodeControlByGroupAndKeyForMode(group: string, key: string, mode: 'fromTo' | 'hoursSum'): FormControl {
    return this.encodedTypesFormDict[group].get(mode).controls[key];
  }

  getDecodeControlByGroupAndKeyGeneral(group: string, key: string): FormControl {
    return this.encodedTypesFormDict[group]?.controls[key];
  }

  getColumnValueByIdx(idx): any {
    return this.dataSource.fullData.slice(this.headerRowIdx + 1)
      .map(item => item['generic_empty_key_' + (idx - (this.staticColumns.length - 1))]);
  }

  getColumnRoleKeyByIdx(colIdx: number, excludeDays: boolean = false): string | undefined {
    return this.selectionPointsTitles.find(key => {
      if (key === 'addDay' && !excludeDays) {
        return this.selectionPoints[key]?.valueExColIdxs.includes(colIdx);
      } else {
        return this.selectionPoints[key]?.valueExColIdx === colIdx;
      }
    });
  }

  getColumnNameByIdx(colIdx: number) {
    const name = this.selectionPoints[this.getColumnRoleKeyByIdx(colIdx, true)]?.title;
    return name ? `(${name})` : undefined;
  }

  isColumnSelected(colIdx: number): boolean {
    return !!this.getColumnRoleKeyByIdx(colIdx);
  }

  isBuildButtonAvailable(): boolean {
    return !!this.selectionPoints.nameColumn.valueEx && !!this.selectionPoints.addDay.valueExColIdxs.length;
  }

  onCompanyChange(event): void {
    if (event) {
      this.companyCtrl.setValue(event);

      if (this.isEmployeeTableReady && this.isBuildButtonAvailable()) {
        this.generateComparingTable();
      }

      this.saveConfigToLS('clientId', event.id);
    }
  }

  onFileChange(output: UploadOutput): void {
    if (output.file) {
      this.readExcel(output.file.nativeFile);
    }
  }

  readExcel(file: any): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const binaryString: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

      if (workbook.SheetNames.length === 1) {
        this.proceedWorkWithExcel(worksheet);
      } else {
        this.sheetNames = workbook.SheetNames;
        this.sheetsData = workbook.Sheets;
        this.sheetOptionsMenuTrigger.openMenu();
      }
    };
    reader.readAsBinaryString(file);
  }

  proceedWorkWithExcel(worksheet: XLSX.WorkSheet) {
    let dataSet: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    dataSet = dataSet.filter(item => item?.length > 0);

    if (dataSet.length) {
      const columns = [];
      const data = [];
      let maxDataRowLength = 0;

      dataSet.forEach(row => maxDataRowLength = row.length > maxDataRowLength ? row.length : maxDataRowLength);

      for (let i = 0; i < maxDataRowLength; i++) {
        columns.push('generic_empty_key_' + (i + 1));
      }

      dataSet.forEach((row: any[]) => {
        const dataDict = { };
        row.forEach((item, idx) => dataDict[columns[idx]] = item);
        data.push(dataDict);
      });

      this.displayedColumns = ['numeric_key', 'fetchedId_key', 'days_worked', 'days_absence', ...columns];
      this.dataSource = new ExcelWidgetDatasource(data, this.paginator);
      this.setPaginatorObs();

      this.dataSource.data.subscribe(() => this.setAvailableToSelectLength());

      window.setTimeout(() => {
        if (this.savedConfig?.headerRowIdx >= 0) {
          this.selectHeaderRow(this.savedConfig.headerRowIdx, this.dataSource.fullData[this.savedConfig.headerRowIdx]);
        }

        if (this.savedConfig?.selectedColumns && Object.keys(this.savedConfig?.selectedColumns)?.length) {
          Object.keys(this.savedConfig.selectedColumns).forEach(key => {
            this.selectionInProgress = key;
            this.selectColumn(this.savedConfig.selectedColumns[key]);
          });
        }
      });
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.dateCtrl.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateCtrl.setValue(ctrlValue);

    if (this.isEmployeeTableReady && this.isBuildButtonAvailable()) {
      this.generateComparingTable();
    }

    this.saveConfigToLS('yearMonth', moment(ctrlValue).format('YYYY-MM'));
  }

  dayValueDisplayFn(key: string): any {
    const value = this.encodedTypesFormDict[key]?.value;
    return (value && !value.noShow && value.decoded) || null;
  }

  setNoFoundEmployeeId(event, columnIdx: number, rowIdx: number) {
    const pureIdx = this.getPureRowIdx(rowIdx);
    if (this.isEmployeeIdColumn(columnIdx)) {
      this.selectionPoints.employeeId.valueEx[pureIdx] = event.target?.value || null;

      if (event.target?.value) {
        this.updateSingleRowData(event.target.value);
      }
    }

    if (this.isEmployeeNameColumn(columnIdx)) {
      this.selectionPoints.nameColumn.valueEx[pureIdx] = event.target?.value || null;
    }

    if (
      event.target?.value && this.getEmployeeIdByRowIdx(rowIdx) && this.getEmployeeNameByRowIdx(rowIdx)
        && this.excludeRowsSelection.isSelected(this.paginator.pageIndex > 0
        ? this.paginator.pageIndex * this.paginator.pageSize + rowIdx : rowIdx)
    ) {
      this.excludeRowsSelection.toggle(this.paginator.pageIndex > 0 ? this.paginator.pageIndex * this.paginator.pageSize + rowIdx : rowIdx);
    }
  }

  loadShiftsDataByEmployees(employeeIds, idIdxMatches): void {
    const companyId = this.companyCtrl.value.livasId;
    const companyCountry = this.companyCtrl.value.country.isoCode;
    const dateFrom = moment(this.dateCtrl.value).startOf('month').format('YYYY-MM-DD');
    const dateTo = moment(this.dateCtrl.value).endOf('month').format('YYYY-MM-DD');

    this.excelWidgetService.getEmployeesWorkedTime({ employeeIds, dateFrom, dateTo, companyId }, companyCountry).subscribe(
      res => {
        employeeIds.forEach(id => {
          const dataSet = res[id];
          const employeeShiftsByDates = { };
          this.workedHoursMatrix[id] = { };
          this.timeManagementMatrix[id] = { };
          this.lockTypetMatrix[id] = { };
          if (dataSet?.length) {
            dataSet.forEach(({ date, shiftStatus, shift_id, totalWorked, total_planned_hours, time_management_type, workhours }) => {
              this.workedHoursMatrix[id][shift_id] = workhours;
              this.timeManagementMatrix[id][shift_id] = time_management_type;
              this.lockTypetMatrix[id][shift_id] = shiftStatus;
              employeeShiftsByDates[moment(date).format('DD/MM')] = { shift_id, totalWorked, total_planned_hours };
            });

            this.shiftsData[idIdxMatches[id]] = employeeShiftsByDates;
            this.workedAbsenceDaysData[id] = { workedDay: res[id].length };
          } else {
            this.selectionPoints.employeeId.valueEx[idIdxMatches[id]] = null;
          }
        });

        this.absencesService.getEmployeeAllAbsencesByRange(dateFrom, dateTo, employeeIds, companyCountry).subscribe(
          resp => {
            Object.keys(resp).forEach(key => {
              this.absencesMatrix[key] = { };
              resp[key].absences.forEach(dateItem =>
                this.absencesMatrix[key][moment(dateItem.date).format('DD/MM')] = {
                  absenceType: dateItem.absence_type,
                  absenceUnpaid: dateItem.absence_unpaid
                }
              );
            });

            Object.keys(resp).forEach(key => this.workedAbsenceDaysData[key].absenceDays = resp[key].absences.length);
          },
          err => this.openErrorSnackbar(err)
        );
      },
      err => {
        this.openErrorSnackbar(err);
      }
    );

    if (Object.keys(this.selectionPoints).filter(key => this.selectionPoints[key].valueExColIdx).length > 2) {
      const month = moment(this.dateCtrl.value).format('YYYY-MM');
      this.excelWidgetService.getMonthInfoByClient({ employeeIds, month, companyId, confirmed: 1 }, companyCountry).subscribe(
        res => {
          Object.keys(res)?.forEach(key => {
            this.totalValuesByEmployee[key] = {
              totalHoursDay: res[key].comfirmed?.day || res[key].saved?.day || 0,
              totalHoursNight: res[key].comfirmed?.night || res[key].saved?.night || 0,
              overtimeDay: res[key].comfirmed?.overtime_day || res[key].saved?.overtime_day || 0,
              overtimeNight: res[key].comfirmed?.overtime_night || res[key].saved?.overtime_night || 0,
              holidaysDay: res[key].comfirmed?.holiday_day || res[key].saved?.holiday_day || 0,
              holidaysNight: res[key].comfirmed?.holiday_night || res[key].saved?.holiday_night || 0
            };
            this.totalValuesByEmployee[key].totalHours = Object.keys(this.totalValuesByEmployee[key]).reduce(
              (acc: number, value: string ) => acc + this.totalValuesByEmployee[key][value], 0
            );
          });
        },
        err => this.openErrorSnackbar(err)
      );
    }
  }

  isSelectedColumn(columnIdx: number, isDayColsIncluded?: boolean): boolean {
    return Object.keys(this.selectionPoints).map(key =>
      this.selectionPoints[key].valueExColIdx || (isDayColsIncluded ? this.selectionPoints[key].valueExColIdxs : null)
    ).flat().includes(columnIdx);
  }

  isColumRelatedToOneOfSelections(columnKeys: string[], columnIdx: number): boolean {
    return columnKeys.some(key => this.selectionPoints[key].valueExColIdx === columnIdx);
  }

  isEmployeeIdColumn(columnIdx: number): boolean {
    return this.selectionPoints.employeeId.valueExColIdx === columnIdx;
  }

  isEmployeeNameColumn(columnIdx: number): boolean {
    return this.selectionPoints.nameColumn.valueExColIdx === columnIdx;
  }

  isDayCompareDisplay(column: number, row: number): boolean {
    return this.isEmployeeTableReady && (this.paginator.pageIndex > 0 || row > this.headerRowIdx)
      && this.selectionPoints.addDay.valueExColIdxs.includes(column);
  }

  getEmployeeIdByRowIdx(rowIdx: number): number {
    const pureIdx = this.getPureRowIdx(rowIdx);
    return this.selectionPoints.employeeId.valueEx?.[pureIdx];
  }

  getEmployeeNameByRowIdx(rowIdx: number): string {
    const pureIdx = this.getPureRowIdx(rowIdx);
    return this.selectionPoints.nameColumn.valueEx?.[pureIdx];
  }

  getEmployeeShiftData(columnIdx: number, rowIdx: number): any {
    const pureIdx = this.getPureRowIdx(rowIdx);
    return this.shiftsData[pureIdx]?.[this.headerRow['generic_empty_key_' + (columnIdx - (this.staticColumns.length - 1))]];
  }

  resetAll(hardReset?: boolean) {
    this.encodedTypesDataSource = undefined;
    this.encodedTypesFormDict = { };
    // this.absencesMatrix = { };
    this.hiddenColumnsIdx = [];
    this.shiftsData = [];
    this.isEmployeeTableReady = false;
    this.isFullDimeDataLoaded = false;
    this.isAbsencesMatrixReady = false;
    this.sheetNames = undefined;
    this.sheetsData = undefined;
    this.processedColumn = undefined;
    this.selectionInProgress = undefined;
    this.headerRowIdx = undefined;
    this.headerRow = undefined;
    this.paginator.pageIndex = 0;
    this.selectionPoints = JSON.parse(JSON.stringify(defaultSelectionPoints));
    this.excludeRowsSelection.clear();

    if (hardReset) {
      this.displayedColumns = [];
      this.dataSource = undefined;
      this.hideBuildAndUnselected = false;
    }
  }

  saveConfigToLS(key: 'clientId' | 'yearMonth' | 'headerRowIdx' | 'selectedColumns', value: any): void {
    const config: ExcelWidgetLastUseConfigModel = { ...this.savedConfig, [key]: value };
    this.savedConfig = config;
    localStorage.setItem(EXCEL_WIDGET_LAST_USE_CONFIG_KEY, JSON.stringify(config));
  }

  readConfigFromLS(): ExcelWidgetLastUseConfigModel {
    return JSON.parse(localStorage.getItem(EXCEL_WIDGET_LAST_USE_CONFIG_KEY));
  }

  openEmployeeCard(employeeId: number): void {
    const companyCountry = this.companyCtrl.value.country.isoCode;
    this.excelWidgetService.openEmployeeCard(employeeId, companyCountry);
  }

  openShiftDialog(shiftStatus: number, shiftId: number, employeeId: number, uniqueValueKey: string | number) {
    if (this.lockTypetMatrix[employeeId] && shiftId) {
      const companyCountry = this.companyCtrl.value.country.isoCode;
      const dialog = this.dialog.open(ShiftParamsDialogComponent, {
        data: {
          shiftStatus: this.lockTypetMatrix[employeeId][shiftId],
          shiftId,
          employeeId,
          decryptedDataForm: this.encodedTypesFormDict[uniqueValueKey],
          companyCountry,
          timeManagementType: this.timeManagementMatrix[employeeId]?.[shiftId],
          workHours: this.workedHoursMatrix[employeeId]?.[shiftId] || { }
        },
        autoFocus: false
      });

      dialog.afterClosed().subscribe(resp => {
        if (resp?.needUpdate) {
          this.updateSingleRowData(employeeId);
        }
      });
    }
  }

  formatToDecimal(value: string | number, points: number = 2): string | number {
    if (typeof value === 'string') {
      value = value.replace(',', '.');
    }
    const numValue = Number(value);
    if (value && !isNaN(numValue)) {
      const stringValue = String(numValue);
      const pointsAfterDot = stringValue.includes('.') ? stringValue.split('.')[1].length : 0;
      return stringValue.length === 1
        ? stringValue
        : pointsAfterDot <= points
          ? numValue.toFixed(pointsAfterDot).replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')
          : numValue.toFixed(points).replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
    } else {
      return value !== undefined && value !== null ? String(value) : value;
    }
  }

  openErrorSnackbar(err: any): void {
    this.snackbar.open(this.translate.instant('COMMON.ERRORS.GENERAL') + ' ' + err.error, undefined, { duration: 5000 });
  }

  getPureRowIdx(rowIdx): number {
    return (this.paginator.pageIndex > 0 ? this.paginator.pageIndex * this.paginator.pageSize + rowIdx : rowIdx)
      - this.headerRowIdx - 1;
  }

  onMouseDown(event: MouseEvent, columnIdx: number, rowIdx: number) {
    if (columnIdx) {
      if (this.unblockClickAction) {
        window.clearInterval(this.unblockClickAction);
      }

      if (this.selectedCells?.length === 1) {
        this.selectedCells = [];
      } else {
        this.unblockClickAction = window.setTimeout(() => this.unblockClickAction = undefined, 500);
        this.isMouseDown = true;
        this.selectionRow = rowIdx;
        this.selectedCells = [columnIdx];
      }
    }
  }

  addToSelectedRows(index: number, preventRemove?: boolean) {
    if (this.selectedRows.includes(this.getPureRowIdx(index))) {
      this.selectedRows.forEach((row, i) => {
        // console.log(row, this.getPureRowIdx(index))
        if ((row === this.getPureRowIdx(index) && !preventRemove)) {
          this.selectedRows = this.selectedRows.filter((_, idx) => idx !== i);
          this.selectedUsers = this.selectedUsers.filter((_, idx) => idx !== i);
          // console.log('-', this.selectedRows)
          // console.log('-', this.selectedUsers)
        }
      });
    } else {
      this.selectedRows.push(this.getPureRowIdx(index));
      this.selectedUsers.push(this.getEmployeeIdByRowIdx(index));
      // console.log('+', this.selectedRows)
      // console.log('+', this.selectedUsers)
    }
  }

  toggleAllRowsSelect(event: { checked: boolean }, columnIdx: number) {
    if (event.checked) {
      const data = this.dataSource.fullData.slice(this.headerRowIdx + 1);
      // console.log(0, this.dataSource.fullData)
      // console.log(0, data)
      this.availableToSelectLength = data.filter((_, idx) => {
        if (!this.isEmployeeNameOrIdError(columnIdx + 1, this.headerRowIdx + 1 + idx)) {
          this.addToSelectedRows(this.headerRowIdx + 1 + idx, true);
          return true;
        } else {
          return false;
        }
      }).length;
    } else {
      this.selectedRows = [];
      this.selectedUsers = [];
    }
  }

  setAvailableToSelectLength() {
    const data = this.dataSource.fullData.slice(this.headerRowIdx + 1);
    this.availableToSelectLength = data.filter((_, idx) =>
      !this.isEmployeeNameOrIdError(1, this.headerRowIdx + 1 + idx)
    ).length;
  }

  isSelectedForMulti(userId: number): boolean {
    return this.selectedUsers.includes(userId);
  }

  updateSelectedShiftsFromExcelForMultipleRows() {
    this.selectedRows.map(row => {
        this.processedRow = row;
        this.updateSelectedShiftsFromExcel(false, true);
      }
    );

    this.selectedRows = [];
    this.selectedUsers = [];
  }

  addMissingShiftsFromExcelForMultipleRows() {
    this.selectedRows.map(row => {
        this.processedRow = row;
        this.addMissingShifts(false, true);
      }
    );

    this.selectedRows = [];
    this.selectedUsers = [];
  }

  confirmFactForShiftsFromExcelForMultipleRows() {
    this.selectedRows.map(row => {
        this.processedRow = row;
        this.confirmFactForSelectedShifts(false, true);
      }
    );

    this.selectedRows = [];
    this.selectedUsers = [];
  }

  onMouseOver(event: MouseEvent, columnIdx: number) {
    if (
      this.isMouseDown && this.selectedCells[this.selectedCells.length - 1] !== columnIdx
      && columnIdx >= this.selectionPoints.addDay.valueExColIdxs[0]
    ) {
      const startValue = this.selectedCells[0];
      const isAsc = startValue < columnIdx;

      if (!this.selectedCells.includes(columnIdx)) {
        this.selectedCells = Array.from(
          { length: isAsc ? (columnIdx - startValue + 1) : (startValue - columnIdx + 1) },
          (_, i) => isAsc ? (startValue + i) : (startValue - i)
        );
      } else if (isAsc && startValue !== columnIdx) {
        this.selectedCells = this.selectedCells.filter(idx => idx <= columnIdx);
      } else if (!isAsc && startValue !== columnIdx) {
        this.selectedCells = this.selectedCells.filter(idx => idx >= columnIdx);
      } else if (startValue === columnIdx) {
        this.selectedCells = [columnIdx];
      }
    }
  }

  onMouseUp() {
    this.isMouseDown = false;
    if (this.selectedCells.length === 1 && this.unblockClickAction) {
      this.selectedCells = [];
    }
  }

  addMissingShifts(proceedOnlySelected: boolean, isPureRow?: boolean) {
    const excelData = this.selectionPoints.addDay.valueEx;
    const processedExcelData = { };
    const pureIdx = isPureRow ? this.processedRow : this.getPureRowIdx(this.processedRow);
    Object.keys(excelData).forEach(key => processedExcelData[key] = excelData[key][pureIdx]);
    const livasMissingShifts = Object.keys(excelData).filter((key, idx) => {
      if (proceedOnlySelected) {
        return processedExcelData[key] !== '-' && !this.shiftsData[pureIdx][key]
               && this.selectedCells.includes(idx + this.selectionPoints.addDay.valueExColIdxs[0]);
      } else {
        return processedExcelData[key] !== '-' && !this.shiftsData[pureIdx][key];
      }
    });
    const livasMissingDates = livasMissingShifts.map(key =>
      (key + '/' + (this.dateCtrl.value as Moment).format('YYYY')).split('/').reverse().join('-')
    );
    const processedLivasData = this.shiftsData[pureIdx];
    const existShiftId = processedLivasData[Object.keys(processedLivasData).find(key => processedLivasData[key]?.shift_id)]?.shift_id;

    if (existShiftId && livasMissingDates.length) {
      const companyCountry = this.companyCtrl.value.country.isoCode;
      const employeeId = this.selectionPoints.employeeId.valueEx[pureIdx];
      this.excelWidgetService.createNewShiftsFromExistByEmployeesDates(
        { shift_id: existShiftId, employees_id: [employeeId], dates: livasMissingDates }, companyCountry
      ).subscribe(() => this.updateSingleRowData(employeeId), err => this.openErrorSnackbar(err));
    }
  }

  updateSelectedShiftsFromExcel(proceedOnlySelected: boolean, isPureRow?: boolean) {
    const companyCountry = this.companyCtrl.value.country.isoCode;
    const excelData = this.selectionPoints.addDay.valueEx;
    const pureIdx = isPureRow ? this.processedRow : this.getPureRowIdx(this.processedRow);
    const processedExcelData = { };
    const employeesByShift = { };

    let processedShiftColumnsKeys = Object.keys(excelData);

    if (proceedOnlySelected) {
      processedShiftColumnsKeys = processedShiftColumnsKeys.filter((key, idx) =>
        this.selectedCells.includes(idx + this.selectionPoints.addDay.valueExColIdxs[0])
      );
    }

    processedShiftColumnsKeys.forEach(key => {
      const shiftId = this.shiftsData[pureIdx]?.[key]?.shift_id;
      const employeeId = this.selectionPoints.employeeId.valueEx[pureIdx];

      if (this.lockTypetMatrix[employeeId] && this.lockTypetMatrix[employeeId][shiftId] !== 'confirmed') {
        const timeManagementType = this.timeManagementMatrix[employeeId]?.[shiftId];
        const decodedShift = this.encodedTypesFormDict[excelData[key][pureIdx]]?.value;
        const excelDataItem = excelData[key][pureIdx];
        const dataObj: WorkedHoursByShiftCrateUpdateInput = {
          employee_id: employeeId,
          type: 'hours',
          source: 0
        };

        if (timeManagementType === '0' && decodedShift?.decoded) {
          const dayMonth = key.split('/');
          const year = this.dateCtrl.value.format('YYYY');
          const { workFrom, workTo, lunchFrom, lunchTo } = decodedShift.fromTo;
          const fromMoment = moment(`${year}-${dayMonth[1]}-${dayMonth[0]} ${workFrom}`);
          let toMoment = moment(`${year}-${dayMonth[1]}-${dayMonth[0]} ${workTo}`);
          toMoment = fromMoment.get('hours') > toMoment.get('hours')
            ? toMoment.add('day', 1)
            : toMoment;
          const fromMomentLunch = moment(`${year}-${dayMonth[1]}-${dayMonth[0]} ${lunchFrom}`);
          let toMomentLunch = moment(`${year}-${dayMonth[1]}-${dayMonth[0]} ${lunchTo}`);
          toMomentLunch = fromMomentLunch.get('hours') > toMomentLunch.get('hours')
            ? toMomentLunch.add('day', 1)
            : toMomentLunch;

          dataObj.start_datetime = fromMoment.format('YYYY-MM-DD HH:mm:ss');
          dataObj.end_datetime = toMoment.format('YYYY-MM-DD HH:mm:ss');
          dataObj.lunch_start_datetime = fromMomentLunch.format('YYYY-MM-DD HH:mm:ss');
          dataObj.lunch_end_datetime = toMomentLunch.format('YYYY-MM-DD HH:mm:ss');
          processedExcelData[shiftId] = dataObj;
          employeesByShift[shiftId] = employeeId;
        } else if (
          timeManagementType === '1' && (decodedShift?.decoded || (decodedShift &&
            (typeof excelDataItem === 'number' || (typeof excelDataItem === 'string' && excelDataItem.includes('/')))))
        ) {
          const { hrsDay, hrsNight, holidaysDay, holidaysNight, overtimeDay, overtimeNight } = decodedShift.hoursSum;
          dataObj.normal_day = hrsDay;
          dataObj.normal_night = hrsNight;
          dataObj.holiday_day = holidaysDay;
          dataObj.holiday_night = holidaysNight;
          dataObj.normal_day_overtime = overtimeDay;
          dataObj.normal_night_overtime = overtimeNight;
          dataObj.holiday_day_overtime = 0;
          dataObj.holiday_night_overtime = 0;
          processedExcelData[shiftId] = dataObj;
          employeesByShift[shiftId] = employeeId;
        }
      }
    });

    Object.keys(processedExcelData).forEach(key => {
      this.excelWidgetService.createWorkedHoursByShift(
        processedExcelData[key],
        Number(key),
        companyCountry
      ).subscribe(
        () => this.updateSingleRowData(employeesByShift[key]),
        err => this.openErrorSnackbar(err)
      );
    });
  }

  confirmFactForSelectedShifts(proceedOnlySelected: boolean, isPureRow?: boolean) {
    const companyCountry = this.companyCtrl.value.country.isoCode;
    const excelData = this.selectionPoints.addDay.valueEx;
    const pureIdx = isPureRow ? this.processedRow : this.getPureRowIdx(this.processedRow);
    let processedShiftColumnsKeys = Object.keys(excelData);

    if (proceedOnlySelected) {
      processedShiftColumnsKeys = processedShiftColumnsKeys.filter((key, idx) =>
        this.selectedCells.includes(idx + this.selectionPoints.addDay.valueExColIdxs[0])
      );
    }

    processedShiftColumnsKeys.forEach(key => {
      const shiftId = this.shiftsData[pureIdx]?.[key]?.shift_id;
      const employeeId = this.selectionPoints.employeeId.valueEx[pureIdx];

      if (this.lockTypetMatrix[employeeId][shiftId] === 'saved') {
        this.excelWidgetService.setWorkedHoursAsConfirmed(shiftId, companyCountry).subscribe(
          () => this.updateSingleRowData(employeeId),
          err => this.openErrorSnackbar({ error: err.error.message })
        );
      }
    });
  }

  updateSingleRowData(employeeId) {
    const companyId = this.companyCtrl.value.livasId;
    const companyCountry = this.companyCtrl.value.country.isoCode;
    const dateFrom = moment(this.dateCtrl.value).startOf('month').format('YYYY-MM-DD');
    const dateTo = moment(this.dateCtrl.value).endOf('month').format('YYYY-MM-DD');

    this.excelWidgetService.getEmployeesWorkedTime(
      { employeeIds: [employeeId], dateFrom, dateTo, companyId }, companyCountry
    ).subscribe(
      res => {
        if (res[employeeId]) {
          this.workedHoursMatrix[employeeId] = { };
          this.timeManagementMatrix[employeeId] = { };
          this.lockTypetMatrix[employeeId] = { };
          const employeeShiftsByDates = { };
          res[employeeId].forEach(({ date, shiftStatus, shift_id, totalWorked, time_management_type, workhours, total_planned_hours }) => {
            this.workedHoursMatrix[employeeId][shift_id] = workhours;
            this.timeManagementMatrix[employeeId][shift_id] = time_management_type;
            this.lockTypetMatrix[employeeId][shift_id] = shiftStatus;
            employeeShiftsByDates[moment(date).format('DD/MM')] = { shift_id, totalWorked, total_planned_hours };
          });
          const dataRowIdx = this.selectionPoints.employeeId.valueEx.indexOf(employeeId);
          this.shiftsData[dataRowIdx] = employeeShiftsByDates;

          if (!this.workedAbsenceDaysData[employeeId]) {
            this.workedAbsenceDaysData[employeeId] = { };
          }
          this.workedAbsenceDaysData[employeeId].workedDay = res[employeeId].length;
        }

        if (Object.keys(this.selectionPoints).filter(key => this.selectionPoints[key].valueExColIdx).length > 2) {
          const month = moment(this.dateCtrl.value).format('YYYY-MM');
          this.excelWidgetService.getMonthInfoByClient(
            { employeeIds: [employeeId], month, companyId, confirmed: 1 }, companyCountry
          ).subscribe(
            res2 => {
              this.totalValuesByEmployee[employeeId] = {
                totalHoursDay: res2[employeeId].comfirmed?.day || res2[employeeId].saved?.day || 0,
                totalHoursNight: res2[employeeId].comfirmed?.night || res2[employeeId].saved?.night || 0,
                overtimeDay: res2[employeeId].comfirmed?.overtime_day || res2[employeeId].saved?.overtime_day || 0,
                overtimeNight: res2[employeeId].comfirmed?.overtime_night || res2[employeeId].saved?.overtime_night || 0,
                holidaysDay: res2[employeeId].comfirmed?.holiday_day || res2[employeeId].saved?.holiday_day || 0,
                holidaysNight: res2[employeeId].comfirmed?.holiday_night || res2[employeeId].saved?.holiday_night || 0
              };
              this.totalValuesByEmployee[employeeId].totalHours = Object.keys(this.totalValuesByEmployee[employeeId]).reduce(
                (acc: number, value: string ) => acc + this.totalValuesByEmployee[employeeId][value], 0
              );
            },
            err => {
              this.openErrorSnackbar(err);
            }
          );
        }

        this.absencesService.getEmployeeAllAbsencesByRange(dateFrom, dateTo, [employeeId], companyCountry).subscribe(
          resp => {
            this.absencesMatrix[employeeId] = { };
            if (resp[employeeId]) {
              resp[employeeId].absences.forEach(dateItem =>
                this.absencesMatrix[employeeId][moment(dateItem.date).format('DD/MM')] = {
                  absenceType: dateItem.absence_type,
                  absenceUnpaid: dateItem.absence_unpaid
                }
              );
              this.workedAbsenceDaysData[employeeId].absenceDays = resp[employeeId].absences?.length;
            }
          },
          err => this.openErrorSnackbar(err)
        );
      },
      err => {
        this.openErrorSnackbar(err);
      }
    );
  }

  getDecodeBlockVisibility(elementKey: string): boolean | '1' | '0' {
    const colIdx = this.getDecodeControlByGroupAndKeyGeneral(elementKey, 'colIdx')?.value;
    const rowIdx = this.getDecodeControlByGroupAndKeyGeneral(elementKey, 'rowIdx')?.value;

    return this.getManagementTypeByRelatedIdxs(colIdx, rowIdx);
  }

  getManagementTypeByRelatedIdxs(colIdxR, rowIdx): boolean | '1' | '0' {
    const colIdx = colIdxR + this.selectionPoints.addDay.valueExColIdxs[0];
    const employeeId = this.selectionPoints.employeeId.valueEx?.[rowIdx];
    const shiftId = this.shiftsData[rowIdx]?.[this.headerRow['generic_empty_key_' + (colIdx - (this.staticColumns.length - 1))]]?.shift_id;

    if (employeeId && shiftId) {
      return this.timeManagementMatrix[employeeId][shiftId];
    } else {
      return false;
    }
  }

  getObjectNumericSum(object: any): number {
    const keys = object ? Object.keys(object) : null;
    if (keys?.length) {
      return keys.reduce((acc: number, key: string) => Number((acc + (object[key] ? Number(object[key]) : 0)).toFixed(2)), 0);
    } else {
      return 0;
    }
  }
}
