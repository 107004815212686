import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { RequestActivitiesComponent } from './request-activities.component';
import { RequestActivitiesRoutingModule } from './request-activities-routing.module';
import { EditActivityCommentComponent } from './edit-activity-comment/edit-activity-comment.component';

@NgModule({
  declarations: [
    RequestActivitiesComponent,
    EditActivityCommentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RequestActivitiesRoutingModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslateModule
  ],
  exports: [
    RequestActivitiesComponent
  ]
})
export class RequestActivitiesModule { }
