import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import moment from 'moment/moment';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  GetAllReasonsGQL,
  GetRequestActivitiesGQL,
  RequestsActivityType,
  UpdateContactRequestActivityGQL
} from '../../generated/graphql';
import { RequestActivitiesDatasource } from './request-activities.datasource';
import { FilterInterface } from '../shared/filter-bar/filter-bar.component';
import { EditActivityCommentComponent } from './edit-activity-comment/edit-activity-comment.component';

@Component({
  selector: 'app-request-activities',
  templateUrl: './request-activities.component.html',
  styleUrls: ['./request-activities.component.scss']
})
export class RequestActivitiesComponent implements OnInit, AfterViewInit {
  moment = moment;

  dataSource: RequestActivitiesDatasource;
  displayedColumns = ['created_at', 'created_by', 'contact', 'phone', 'company', 'reason', 'comment'];
  filters = ['projectManager', 'dateRange', 'phone'];
  reasons = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<RequestsActivityType>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private getRequestActivitiesGQL: GetRequestActivitiesGQL,
    private updateContactRequestActivityGQL: UpdateContactRequestActivityGQL,
    private getAllReasonsGQL: GetAllReasonsGQL,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataSource = new RequestActivitiesDatasource(this.getRequestActivitiesGQL);
    this.getReasons().subscribe( val => {
      this.reasons = val.data.allReasons.edges.map( res => {
        return { id: res.node.id, title: res.node.title };
      });
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  onFilterChanges(event: FilterInterface) {
    const filters = { };
    if (event.phone) { filters['phone'] = event.phone; }
    if (event.projectManager) { filters['createdBy'] = event.projectManager; }
    if (event.dateRange?.dateFrom && event.dateRange?.dateTo) {
      filters['createdAt_Gte'] = moment(event.dateRange.dateFrom).format('YYYY-MM-DD');
      filters['createdAt_Lte'] = moment(event.dateRange.dateTo).format('YYYY-MM-DD');
    }

    this.dataSource.filters.next(filters);
  }

  getReasons() {
    return this.getAllReasonsGQL.fetch();
  }

  onOpenEditDialog(id, comment): void {
    this.dialog.open(EditActivityCommentComponent, {
      data: {
        comment: comment
      }
    }).afterClosed().subscribe( res => {
      if (res) {
        this.onUpdate(id, res.data.comment, 'comment');
      }
    });
  }

  updateActivity(input) {
    return this.updateContactRequestActivityGQL.mutate({input});
  }

  onUpdate(id, updatable, updatableValue = 'reason') {
    switch (updatableValue) {
      case 'reason':
        this.updateActivity({ id: id, reason: updatable }).subscribe();
        break;
      case 'comment':
        this.updateActivity({ id: id, comment: updatable }).subscribe();
        break;
    }
  }

  getContactName(activity: RequestsActivityType) {
    return activity.contact
      ? `${ activity.contact.firstName || '' } ${ activity.contact.lastName || '' }`
      : `${ activity.clientContact?.firstName || '' } ${ activity.clientContact?.lastName || '' }`;
  }
}
