import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import moment, { Moment } from 'moment/moment';
import { Location } from '@angular/common';

import {
  OverdueTaskTableFieldsFragment,
  TaskDetailViewFieldsFragment,
  TaskStatus,
  TaskType,
  UpdateOperationsTaskMutationVariables
} from '../../../../generated/graphql';
import { StandupDashboardServiceService } from '../standup-dashboard-service.service';
import { TaskServiceService } from '../../../planner/task-service.service';
import { PusherService } from '../../../pusher/pusher.service';
import { NewTaskDialogComponent } from '../../../planner/new-task-dialog/new-task-dialog.component';
import { ReasonChipsData } from '../../../shared/reason-with-comment/reason-with-comment.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-meeting-table',
  templateUrl: './meeting-client.component.html',
  styleUrls: ['./meeting-client.component.scss']
})
export class MeetingClientComponent implements OnInit, OnDestroy {
  private pusherSubscription: Subscription;
  tableData: MatTableDataSource<OverdueTaskTableFieldsFragment> = new MatTableDataSource([]);
  displayedColumns: Iterable<string> = [
    'manager', 'planned_date', 'status', 'description', 'nextAction', 'metric', 'reasonComment', 'metricDate', 'client', 'candidate'
  ];
  displayAssignedOnly = true;
  isLoading = true;

  @Input() dashboardId: any;
  @Input() dashboardMembers: any;
  @Input() cardTitle: string;
  @Input() cardDescription: string;
  @Input() filterTaskCategoriesKeys: string;
  @Input() excludeTaskCategoriesKeys: string;
  @Input() excludeStatuses: string;
  @Input() includeFromChildDashboards: boolean;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dashboardService: StandupDashboardServiceService,
    private tasksService: TaskServiceService,
    private pusherService: PusherService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.loadMeetingTasks(this.displayAssignedOnly);
    this.connectPusherTaskUpdate();
    this.tableData.sort = this.sort;
  }

  loadMeetingTasks(meetingOnly?: boolean) {
    this.isLoading = true;

    const categories = environment.env === 'prod'
      ? ['U0FDYXRlZ29yeVR5cGU6MTI1', 'U0FDYXRlZ29yeVR5cGU6MTI2', 'U0FDYXRlZ29yeVR5cGU6MTAx']
      : ['U0FDYXRlZ29yeVR5cGU6Ng=='];

    this.tasksService.getMeetingTasks(
      this.dashboardId,
      meetingOnly,
      this.dashboardMembers,
      categories,
      this.filterTaskCategoriesKeys,
      this.excludeTaskCategoriesKeys,
      this.excludeStatuses,
      this.includeFromChildDashboards
    ).subscribe(
      data => {
        if (data.data?.tasks) {
          this.tableData.data = data.data.tasks.edges.map(edge => {
            return {
              ...edge.node,
              reasonSet: edge.node.metricvalueSet.edges[0]
                ? this.dashboardService.groupCommentsByReasonSet(edge.node.metricvalueSet.edges[0].node.metricvaluecommentSet as any)
                : null
            };
          });
          this.tableData.sort = this.sort;
        }
        this.isLoading = false;
      },
      () => this.isLoading = false
    );
  }

  openDescription(template: TemplateRef<any>) {
    this.dialog.open(template, { autoFocus: false });
  }

  connectPusherTaskUpdate() {
    this.pusherSubscription = this.pusherService.dashboardTaskUpdateEmitter.subscribe(event => {
      const taskIdx = this.tableData.data.findIndex(item => item.id === event.task_id);

      if (taskIdx > -1) {
        this.dashboardService.getUpdatedTask(event.task_id).subscribe(upTask => {
          this.tableData[taskIdx] = {
            ...this.tableData[taskIdx],
            date: upTask.date,
            status: upTask.status,
            comment: upTask.comment
          };
          this.tableData.data = [ ...this.tableData.data ];
        });
      }
    });
  }

  onDateChange(date: Moment, task: TaskType) {
    const newDate = moment(date).format('YYYY-MM-DD');
    if (task.date !== newDate) {
      this.updateTask({ taskId: task.id, date: newDate }, task);
    }
  }

  onStatusChange($event: TaskStatus, task: any) {
    this.updateTask({ taskId: task.id, status: $event }, task);
  }

  updateTask(updatesTask: UpdateOperationsTaskMutationVariables, task?: TaskType) {
    this.tasksService.updateTaskPartial(updatesTask).subscribe(
      resp => {
        task.date = resp.data.updateOperationsTask.task.date;
        task.status = resp.data.updateOperationsTask.task.status;
      }, () => { }
    );
  }

  ngOnDestroy() {
    if (this.pusherSubscription) {
      this.pusherSubscription.unsubscribe();
    }
  }

  openTaskDialog(task: TaskDetailViewFieldsFragment) {
    const config: MatDialogConfig<any> = {
      data: { task },
      panelClass: 'rounded-dialog-16',
      width: '600px',
      height: '640px',
      autoFocus: false
    };
    if (task) {
      this.location.replaceState(`/standups/${encodeURIComponent(task.id)}`);
    }
    this.dialog.open(NewTaskDialogComponent, config).afterClosed().subscribe(value => {
      if (value?.delete) {
        this.snackBar.open(
          this.translate.instant('DELETED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
        );
        this.loadMeetingTasks(this.displayAssignedOnly);
      } else if (value?.id) {
        task.date = value.date;
        task.status = value.status;
        task.category = value.category || null;
        task.comment = value.comment;
        task.dashboard = value.dashboard;
        this.snackBar.open(
          this.translate.instant('UPDATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
        );
      }
      this.location.replaceState('/standups');
    });
  }

  getOverdueStatusColor(date) {
    if (moment(date).isSame(moment(), 'day')) {
      return 'warn';
    } else if (moment(date).diff(moment(), 'day') < 0) {
      return 'alert';
    } else {
      return 'regular';
    }
  }

  getCurrentMetricValue(element: OverdueTaskTableFieldsFragment) {
    return element.metricvalueSet?.edges.length ? element.metricvalueSet.edges[0].node : element.metricvalueLevel2.edges[0]?.node;
  }

  formatInnerText(text: string): string {
    return text.replace(/\n/g, '<br>');
  }
}
