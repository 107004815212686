<div class='metric-table-container'>
  <div fxLayout fxLayoutAlign='space-between'>
    <div fxLayout fxLayoutAlign='start center' fxLayoutGap='48px'>
      <ng-template #descriptionTemplate>
        <div class="description-template" [innerHTML]="formatInnerText(cardDescription | linkify)"></div>
      </ng-template>

      <div class='table-title'>{{ cardTitle }}</div>

      <mat-icon
        class="info-icon"
        [matTooltip]="'OPEN_DESCRIPTION' | translate"
        [matTooltipPosition]="'above'"
        (click)='openDescription(descriptionTemplate)'
      >info</mat-icon>

      <app-filter-bar
        *ngIf='initDates'
        [filters]="['dateRange']"
        [initialValue]='initDates'
        [smallNewDateRange]="true"
        [preventFiltersStore]="true"
        (filterChanges)="onFilterChanges($event)"
      ></app-filter-bar>
    </div>
  </div>

  <div *ngIf='Object.keys(metricSelectionDict).length' class="table-actions" fxLayout fxLayoutAlign='start center'>
    <button mat-flat-button color='accent' (click)="createMultipleTasks()">{{ 'CREATE_1LVL_TASKS' | translate }}</button>
  </div>

  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf='isLoading' [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <table mat-table [dataSource]='tableData' class='mat-elevation-z0' matSort>
    <!-- Is Workis Column -->
    <ng-container matColumnDef='isWorkis'>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef='let element'>
        <img *ngIf='!element.isTotalRow && element.workis'
             class='workis-icon'
             src='assets/favicon/favicon-96x96-workis.png'
        >
      </td>
    </ng-container>

    <!-- Selection Column -->
    <ng-container matColumnDef='selection'>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef='let element' (click)="$event.stopPropagation()">
        <mat-checkbox
          *ngIf='!element.isTotalRow && !element.isEmptyGroup'
          [checked]="metricSelectionDict[element.id]"
          [disabled]="element.nextTask"
          (change)="onSelectionChange(element)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <!-- Is Important Column -->
    <ng-container matColumnDef='isImportant'>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef='let element'>
        <button *ngIf='!element.isTotalRow && !element.isEmptyGroup' mat-icon-button (click)='$event.stopPropagation(); changeImportance(element)'>
          <mat-icon [class.warning]="element.isImportant">priority_high</mat-icon>
        </button>
        <div *ngIf='element.isTotalRow' class='total-counters'>
          <span *ngIf="!element.isEmptyGroup">{{ metricCounts[element.groupId]?.total }}</span>
          <span *ngIf='!metricCounts[element.groupId].total || element.isEmptyGroup'>0</span>/<span>{{ metricCounts[element.groupId]?.important }}</span>!
        </div>
      </td>
    </ng-container>

    <!-- PM Column -->
    <ng-container matColumnDef='pm'>
      <th mat-header-cell *matHeaderCellDef>{{ 'MANAGER' | translate }} / {{ 'DATE' | translate }}</th>
      <td mat-cell *matCellDef='let element' [class.highlight-red]='isMeasureValueExceeded(element)'>
        <span *ngIf='!element.isTotalRow'>{{ element[0].managerName }}</span>
        <br/>
        <span *ngIf='!element.isTotalRow' class='metric-date'>{{ element[0].date }}</span>
      </td>
    </ng-container>

    <!-- Client Column -->
    <ng-container matColumnDef='client'>
      <th mat-header-cell *matHeaderCellDef>{{ 'CLIENT.CLIENT' | translate }} / {{ 'ORDER.ORDER' | translate }}</th>
      <td mat-cell *matCellDef='let element' [class.highlight-red]='isMeasureValueExceeded(element[0])'>
        <div *ngIf='!element[0].isTotalRow' fxLayout="column">
          <div>{{ element[0].client?.name || element[0].order?.company?.name }}</div>
          <div *ngIf='element[0].order' class="order-data">{{ element[0].order?.specification?.positionTitle }} ({{ relayIdService.parseId(element[0].order?.id) }})</div>
        </div>
      </td>
    </ng-container>

    <!-- Measure Value Column -->
    <ng-container matColumnDef='value'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'VALUE_DB' | translate }}</th>
      <td mat-cell *matCellDef='let element' [class.highlight-red]='isMeasureValueExceeded(element[0])'>
        <div fxLayout="column" fxLayoutAlign="center space-between" fxLayoutGap="4px">
          <ng-container *ngIf="!element[0].isExpanded">
            <span *ngFor="let el of element | slice:0:3">
              {{ el.value || '0' }}
            </span>
          </ng-container>
          <ng-container *ngIf="element[0].isExpanded">
            <span *ngFor="let el of element">
              {{ el.value || '0' }}
            </span>
          </ng-container>
        </div>
        <div *ngIf='element[0].isTotalRow' class='total-counters'>
          <span>
            {{metricGoalVsValueSum[element[0].groupName]?.valueSum}}
          </span>
          <span *ngIf='!metricGoalVsValueSum[element[0].groupName].valueSum'>
            0
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef='subvalue'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SUBVALUE' | translate }}</th>
      <td mat-cell *matCellDef='let element' [class.highlight-red]='isMeasureValueExceeded(element[0])'>
        <div fxLayout="column" fxLayoutAlign="center space-between" fxLayoutGap="4px">
          <ng-container *ngIf="!element[0].isExpanded">
            <span *ngFor="let el of element | slice:0:3">
              {{ el.subvalue || '0' }}
            </span>
          </ng-container>
          <ng-container *ngIf="element[0].isExpanded">
            <span *ngFor="let el of element">
              {{ el.subvalue || '0' }}
            </span>
          </ng-container>
        </div>
        <div *ngIf='element[0].isTotalRow' class='total-counters'>
          <span>
            {{metricGoalVsValueSum[element[0].groupName]?.valueSum}}
          </span>
          <span *ngIf='!metricGoalVsValueSum[element[0].groupName].valueSum'>
            0
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Goal Column -->
    <ng-container matColumnDef='total'>
      <th mat-header-cell *matHeaderCellDef>{{ 'TOTAL' | translate }}</th>
      <td mat-cell *matCellDef='let element' [class.highlight-red]='isMeasureValueExceeded(element[0])'>
        <div fxLayout="column" fxLayoutAlign="center space-between" fxLayoutGap="4px">
          <ng-container *ngIf="!element[0].isExpanded">
            <span *ngFor="let el of element | slice:0:3">
              {{ el.total || '0' }}
            </span>
          </ng-container>
          <ng-container *ngIf="element[0].isExpanded">
            <span *ngFor="let el of element">
              {{ el.total || '0' }}
            </span>
          </ng-container>
        </div>
        <div *ngIf='element[0].isTotalRow' class='total-counters'>
          <span>
            {{metricGoalVsValueSum[element[0].groupName]?.goalSum}}
          </span>
          <span *ngIf='!metricGoalVsValueSum[element[0].groupName].goalSum === 0'>
            0
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Reason/Comment Column -->
    <ng-container matColumnDef='reasonComment'>
      <th mat-header-cell *matHeaderCellDef>{{ 'REASON_WITH_COMMENT' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <div fxLayout fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutGap="4px">
            <ng-container *ngIf="!element[0].isExpanded">
              <div *ngFor="let el of element | slice:0:3" class='reasons-container'>
                <app-reason-with-comment
                  *ngFor='let reason of el.reasonSet'
                  [reason]='reason'
                  [managerName]='el.managerName'
                  [metricValueCandidate]="{ firstName: el.candidate?.firstName,
                                          lastName: el.candidate?.lastName,
                                          livasId: el.candidate?.livasId,
                                          employeeLivasId: el.candidate?.employeeLivasId,
                                          country: el.candidate?.country }"
                  [metricValueClient]="el.client ?? { name: '' }"
                  [metricGroup]='el.groupName'
                  (click)="$event.stopPropagation()"
                ></app-reason-with-comment>
              </div>
            </ng-container>
            <ng-container *ngIf="element[0].isExpanded">
              <div *ngFor="let el of element" class='reasons-container'>
                <app-reason-with-comment
                  *ngFor='let reason of el.reasonSet'
                  [reason]='reason'
                  [managerName]='el.managerName'
                  [metricValueCandidate]="{ firstName: el.candidate?.firstName,
                                          lastName: el.candidate?.lastName,
                                          livasId: el.candidate?.livasId,
                                          employeeLivasId: el.candidate?.employeeLivasId,
                                          country: el.candidate?.country }"
                  [metricValueClient]="el.client ?? { name: '' }"
                  [metricGroup]='el.groupName'
                  (click)="$event.stopPropagation()"
                ></app-reason-with-comment>
              </div>
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef='expand'>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef='let element'>
        <button
          *ngIf="element.length > 3"
          mat-icon-button
          (click)="element ? element[0].isExpanded = !element[0].isExpanded : ''">
          <mat-icon
            [ngClass]="{ 'rotate-icon': element[0].isExpanded }">
            expand_more
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Action Category Column -->
    <ng-container matColumnDef='actionCategory'>
      <th mat-header-cell *matHeaderCellDef>{{ 'CATEGORY' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <mat-form-field *ngIf='!element.isTotalRow && !element.isEmptyGroup' (click)="$event.stopPropagation()">
          <mat-label *ngIf='!selectedNextActions[element.id].l1'>{{ (element.nextActions?.length ? 'Select category' : 'No categories') | translate }}</mat-label>
          <mat-select
            [(ngModel)]='selectedNextActions[element.id].l1'
            [disabled]='!element.nextActions?.length || element.nextTask'
            (selectionChange)="onNextActionChange($event, element.nextActions, element.id, element.taskCategory, 'nextTask', element.client?.id, element.candidate?.id, element)"
          >
            <mat-option *ngFor='let nextAction of element.nextActions' [value]='nextAction.id'>
              {{ nextAction.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Task Comment -->
    <ng-container matColumnDef='taskComment'>
      <th mat-header-cell *matHeaderCellDef>{{ 'TASK_COMMENT' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <div
          *ngIf='!element.isTotalRow && element.nextTask'
          [class.placeholder]='!nextTaskComments[element.id].l1'
          [matTooltip]='nextTaskComments[element.id].l1'
          (click)="$event.stopPropagation(); openTaskDialog(element, 'nextTask')"
        >
          {{ nextTaskComments[element.id].l1 || ('ADD_COMMENT' | translate)}}
        </div>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef='taskStatus'>
      <th mat-header-cell *matHeaderCellDef>
        <span class='col-head'>{{ 'STAND_UP_TASK' | translate }}</span>
        {{ 'STATUS' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <app-task-status-pill
          *ngIf='!element.isTotalRow && element.nextTask'
          [task]="element.nextTask"
          [disableStatusChange]="isTaskStatusChangeDisabled(element.nextTask)"
          (statusChange)="onStatusChange($event, element.nextTask)"
        ></app-task-status-pill>
      </td>
    </ng-container>

    <!-- Num Of Calls Column -->
    <ng-container matColumnDef='taskNumericPlan'>
      <th mat-header-cell *matHeaderCellDef>
        {{ 'PLAN' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf='!element.isTotalRow'>
          {{ getPlannedCustomFieldValue(element.nextTask) || '-' }}
        </span>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef='taskDate'>
      <th mat-header-cell *matHeaderCellDef>{{ 'DUE_DATE' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <div *ngIf='!element.isTotalRow && element.nextTask?.date' (click)="$event.stopPropagation()">
          <app-custom-date-picker
            [initValue]='element.nextTask.date'
            (valueChange)='onDateChange($event, element.nextTask.date, element.nextTask)'
          ></app-custom-date-picker>
        </div>
      </td>
    </ng-container>

    <!-- Assigned Task PM Column -->
    <ng-container matColumnDef='assignedTaskPm'>
      <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <app-popup-select-complete
          *ngIf='!element.isTotalRow && element.nextTask'
          [selected]='element.nextTask?.completedBy'
          [addAvailable]='!element.nextTask?.completedBy?.length'
          [removeAvailable]='false'
          (changed)='onSelectedProfilesChange($event, element.nextTask)'
        ></app-popup-select-complete>
      </td>
    </ng-container>

    <!-- Action Category Column L2 -->
    <ng-container matColumnDef='actionCategoryL2'>
      <th mat-header-cell *matHeaderCellDef>{{ 'CATEGORY' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <mat-form-field *ngIf='!element.isTotalRow && !element.isEmptyGroup' (click)="$event.stopPropagation()">
          <mat-label *ngIf='!selectedNextActions[element.id].l2'>{{ (element.nextActions?.length ? 'SELECT_CATEGORY' : 'NO_CATEGORIES') | translate }}</mat-label>
          <mat-select
            [(ngModel)]='selectedNextActions[element.id].l2'
            [disabled]='!element.nextTask || !element.nextActions?.length || element.nextTaskLevel2'
            (selectionChange)="onNextActionChange($event, element.nextActions, element.id, element.taskCategory, 'nextTaskLevel2', element.client?.id, element.candidate?.id, element)"
          >
            <mat-option *ngFor='let nextAction of element.nextActions' [value]='nextAction.id'>
              {{ nextAction.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Task Comment L2 -->
    <ng-container matColumnDef='taskCommentL2'>
      <th mat-header-cell *matHeaderCellDef>{{ 'TASK_COMMENT' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <div
          *ngIf='!element.isTotalRow && element.nextTaskLevel2'
          [class.placeholder]='!nextTaskComments[element.id].l2'
          [matTooltip]='nextTaskComments[element.id].l2'
          (click)="$event.stopPropagation(); openTaskDialog(element, 'nextTaskLevel2')"
        >
          {{ nextTaskComments[element.id].l2 || ('ADD_COMMENT' | translate)}}
        </div>
      </td>
    </ng-container>

    <!-- Status Column L2 -->
    <ng-container matColumnDef='taskStatusL2'>
      <th mat-header-cell *matHeaderCellDef>
        <span class='col-head'>{{ '2_LEVEL_TASK' | translate }}</span>
        {{ 'STATUS' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <app-task-status-pill
          *ngIf='!element.isTotalRow && element.nextTaskLevel2'
          [task]="element.nextTaskLevel2"
          [disableStatusChange]="isTaskStatusChangeDisabled(element.nextTaskLevel2)"
          (statusChange)="onStatusChange($event, element.nextTaskLevel2)"
        ></app-task-status-pill>
      </td>
    </ng-container>

    <!-- Date Column L2 -->
    <ng-container matColumnDef='taskDateL2'>
      <th mat-header-cell *matHeaderCellDef>{{ 'DUE_DATE' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <div *ngIf='!element.isTotalRow && element.nextTaskLevel2?.date' (click)="$event.stopPropagation()">
          <app-custom-date-picker
            [initValue]='element.nextTaskLevel2.date'
            (valueChange)='onDateChange($event, element.nextTaskLevel2.date, element.nextTaskLevel2)'
          ></app-custom-date-picker>
        </div>
      </td>
    </ng-container>

    <!-- Assigned Task PM Column L2 -->
    <ng-container matColumnDef='assignedTaskPmL2'>
      <th mat-header-cell *matHeaderCellDef>{{ 'ASSIGNED' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <app-popup-select-complete
          *ngIf='!element.isTotalRow && element.nextTaskLevel2'
          [selected]='element.nextTaskLevel2?.completedBy'
          [addAvailable]='!element.nextTaskLevel2?.completedBy?.length'
          [removeAvailable]='false'
          (changed)='onSelectedProfilesChange($event, element.nextTaskLevel2)'
        ></app-popup-select-complete>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns;'
        [class.highlight-border]="row.isGroupRow"
        [class.total-row]="row.isTotalRow"
    ></tr>
  </table>
</div>

