<mat-form-field appearance='outline' style='width: 100%;'>
  <mat-label>{{ 'CATEGORY' | translate }}</mat-label>
  <input
    matInput
    type='text'
    [formControl]='categoryControl'
    [matAutocomplete]='categoryAutocomplete'
    (focusout)="checkSelection()"
  >
  <mat-autocomplete
    #categoryAutocomplete='matAutocomplete'
    [class]='customAutocompleteClass'
    [displayWith]='displayCategory'
    (optionSelected)='selectedChange.emit($event.option.value); selectionCache = $event.option.value'
  >
    <mat-optgroup *ngFor='let group of selectedCategories' [label]='getGroupTitle(group)'>
      <mat-option *ngFor='let category of group.children' [value]='category'>
        {{ category.title }}
        <mat-icon *ngIf='category.hint' [matTooltip]='category.hint' class='category-option-icon'>info</mat-icon>
      </mat-option>
    </mat-optgroup>
    <mat-divider></mat-divider>
    <mat-option class="pseudo-option-sac" [disabled]='true'>
      <mat-slide-toggle
        inline='true'
        color='primary'
        class='options-slider'
        [matTooltip]='"SHOW_ALL_CATEGORIES_INFO" | translate'
        [(ngModel)]='showAllOptions'
        (ngModelChange)='updateFilteredCategories(lastSearchedCategory)'
      >
        {{ 'SHOW_ALL_CATEGORIES' | translate }}
      </mat-slide-toggle>

      <div
        class="field-cleaner"
        [class.active]="categoryControl.value"
        (click)="categoryControl.setValue(''); selectedChange.emit(null); selectionCache = undefined"
      >
        <mat-icon>delete_forever</mat-icon>
        <span>Clear selection</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
